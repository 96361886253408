<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import TextareaWithAudioInput from '@/components/inputs/TextareaWithAudioInput.vue';
import { onBeforeRouteLeave } from 'vue-router';

const { t, locale } = useI18n();

const props = defineProps({
  sectionContentItemId: {
    type: String,
    required: true,
  },
  sectionContentItemIndex: {
    type: Number,
    required: true,
  },
  resourceId: {
    type: String,
    required: true,
  },
  question: {
    type: Object,
    required: true,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['interactionStarted', 'questionSolved', 'scrollBy', 'undoLastScroll']);

const localQuestion = ref(props.question);
const unsavedChanges = ref(false);
const alertStore = useAlertStore();
const authStore = useAuthStore();
const hasBeenCheckedCorrect = ref(false); // disallow multiple checks (at least until remounted...)
const isSolved = ref(false);
const isWrong = ref(false);
const feedback = ref('');
const isGettingFeedback = ref(false);
const courseInteractionStore = useCourseInteractionStore();
const isTranscribingAudio = ref(false);
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

onBeforeUnmount(async () => {
  if (unsavedChanges.value) {
    await saveQuestion();
  }
});

onMounted(async () => {
  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      e.preventDefault();
      return true; // Show browser's default confirmation dialog
    }
  };
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveQuestion();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const getAnswerValue = () => {
  return props.isEditing ? localQuestion.value.sample_answer : localQuestion.value.attempted_answer;
};

const setAnswerValue = (value: string) => {
  console.log('setAnswerValue', value);
  if (props.isEditing) {
    localQuestion.value.sample_answer = value;
    unsavedChanges.value = true;
  } else {
    localQuestion.value.attempted_answer = value;
    emit('interactionStarted');
  }
};

const saveQuestion = async () => {
  if (!props.isEditing) return;
  console.log('saveQuestion', localQuestion.value);
  await (
    await getApiClient()
  ).courseSectionItems
    .updateOpenQuestionInContentItemWithResource(props.sectionContentItemId, props.question.id, {
      question_text: localQuestion.value.question_text,
      question_media_item_id: localQuestion.value.question_media_item_id,
      sample_answer: localQuestion.value.sample_answer,
    })
    .then(() => {
      unsavedChanges.value = false;
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave question', 'Error', error);
      throw new Error('Failed to autosave question');
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      setTimeout(() => {
        saveQuestion();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

const submitAnswer = async () => {
  if (!courseInteractionFetchCompleted.value) return;
  if (hasBeenCheckedCorrect.value) return; // disallow multiple checks (at least until remounted...)
  isGettingFeedback.value = true;
  await courseInteractionStore
    .evaluateOpenQuestion(
      props.sectionIndex,
      props.sectionContentItemIndex,
      props.resourceId,
      localQuestion.value.id,
      localQuestion.value.attempted_answer,
    )
    .then(async (response) => {
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      await authStore.fetchUserXp();
      if (response.is_correct) {
        isSolved.value = true;
        hasBeenCheckedCorrect.value = true;
      } else {
        isWrong.value = true;
        alertStore.playMajorFailureSound();
      }
      feedback.value = response.feedback;
      emit('questionSolved', true);
    })
    .catch((error) => {
      alertStore.error('Failed to evaluate form', 'Error', error);
      console.error(error);
      throw new Error('Failed to evaluate form');
    })
    .finally(() => {
      isGettingFeedback.value = false;
    });
};
</script>

<template>
  <div
    class="border-gray-200 shadow-sm rounded-lg p-2 text-xs md:text-sm"
    :class="{
      'bg-white': !isSolved && !isWrong,
      'bg-teal-100 text-teal-600': isSolved,
      'bg-red-100 text-red-600': isWrong,
    }"
  >
    <div class="uppercase text-xs font-semibold">Frage</div>
    <textarea
      v-model="localQuestion.question_text"
      class="w-full px-2 py-0 my-2 resize-none text-xs md:text-sm rounded-lg text-start"
      :class="{
        'border-gray-200': props.isEditing,
        'border-gray-200 shadow-sm': !props.isEditing,
      }"
      :disabled="!props.isEditing"
      rows="1"
      @input="
        (event) => {
          const target = event.target as HTMLInputElement;
          updateMinTextareaHeight(target);
          if (props.isEditing) unsavedChanges = true;
        }
      "
    />

    <div class="pl-7 flex items-center text-xs md:text-sm">
      <span translate="no" class="no-translate material-symbols-outlined text-2xl">lightbulb</span>
      <div class="relative w-full flex-col flex">
        <div class="w-full flex relative">
          <TextareaWithAudioInput
            :model-value="getAnswerValue()"
            class="w-full pl-2 pr-12 py-0 my-2 mx-4"
            :placeholder="props.isEditing ? 'Musterlösung' : 'Antwort'"
            :disabled="isSolved || isGettingFeedback || isTranscribingAudio"
            :allowAudioInput="!props.isEditing"
            :rows="2"
            @input="
              (event) => {
                const target = event.target as HTMLInputElement;
                if (isWrong) feedback = 'Genau, einfach nochmal probieren! 💪';
                isWrong = false;
                updateMinTextareaHeight(target, 2);
                setAnswerValue(target?.value);
              }
            "
            @scrollBy="(distance) => emit('scrollBy', distance)"
            @undoLastScroll="emit('undoLastScroll')"
            @transcriptionInProgress="isTranscribingAudio = $event"
          >
            <!-- Pass send button as slot -->
            <template #actions>
              <div class="flex flex-col">
                <button
                  @click="submitAnswer"
                  type="button"
                  v-show="!props.isEditing"
                  :disabled="!localQuestion.attempted_answer || isSolved || isGettingFeedback || isTranscribingAudio"
                  class="z-10 rounded-md m-0.5 h-7 w-7 flex-col flex"
                  :class="[
                    isSolved || isGettingFeedback || isTranscribingAudio
                      ? 'bg-gray-500 focus:ring-2 cursor-not-allowed focus:ring-blue-600 focus:z-10 focus:outline-none'
                      : localQuestion.attempted_answer
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-blue-300 hover:bg-blue-300',
                  ]"
                >
                  <span
                    v-show="!isGettingFeedback"
                    translate="no"
                    class="-mt-1 material-symbols-outlined notranslate text-white text-3xl"
                  >
                    send
                  </span>
                  <div
                    v-show="isGettingFeedback"
                    class="animate-spin inline-block mt-1 ml-1 w-5 h-5 border-[2px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  />
                </button>
              </div>
            </template>
          </TextareaWithAudioInput>
        </div>
        <div class="-mt-1 pl-5 italic text-xs">{{ feedback }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
