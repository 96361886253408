<script setup>
import { ref, computed, reactive, onMounted, watch, nextTick } from 'vue';
import PatientCarousel from '@/components/CaseCarousel.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { router } from '@/router';
import { useCaseStore, useCaseInteractionStore, usePatientInteractionStore, useAuthStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import InteractionInputToggleGroup from '@/components/inputs/InteractionInputToggleGroup.vue';
import { HSTooltip } from 'preline';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import ProfileImage from '@/components/ProfileImage.vue';

const { t } = useI18n();

const caseStore = useCaseStore();
const { currentCase } = storeToRefs(caseStore);
const authStore = useAuthStore();
const isSubmitting = ref(false);

const isLoading = ref(true);
const originChapterId = ref('');

const task = ref(null);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: false,
  },
});

onMounted(async () => {
  await router.isReady();

  const caseInteractionStore = useCaseInteractionStore();
  // reset learning objectives
  caseInteractionStore.resetLearningObjectives();
  // check if we have a sectionId in the query params
  // if so, we need to set the learning objectives for the section
  if (router.currentRoute.value.query.sectionId) {
    const sectionId = router.currentRoute.value.query.sectionId;
    console.debug('Section ID: ' + sectionId);
    // set the learning objectives for the section
    await caseInteractionStore.setLearningObjectivesForSection(sectionId);
  } else {
    console.debug('No section ID in query params');
  }

  if (router.currentRoute.value.query.chapterId) {
    originChapterId.value = router.currentRoute.value.query.chapterId;
    console.debug('Chapter ID: ' + originChapterId.value);
    // for later routing back to origin
  } else {
    console.debug('No Chapter ID in query params');
  }

  console.debug('Loaded learning objectives: ' + JSON.stringify(caseInteractionStore.currentLearningObjectives));

  caseStore
    .setCurrentCase(router.currentRoute.value.params.caseId)
    .then(() => {
      console.debug('Loaded case: ' + currentCase.value.id);
    })
    .catch((error) => {
      console.error('Error while loading case: ' + error);
    })
    .finally(() => {
      isLoading.value = false;
    });
});

async function onStartCaseClick() {
  console.debug('Starting case ' + currentCase.value.id);

  isSubmitting.value = true;
  await router.isReady();

  // user clicked on "start patient interaction" button, so we create a new patient interaction object
  // but we do not start streaming here, this will be done in the patient interaction view.

  // create case interaction
  const caseInteractionStore = useCaseInteractionStore();
  await caseInteractionStore.createCaseInteraction(currentCase.value.id, t);
  // console.debug('Current case interaction ID: ' + caseInteractionStore.currentCaseInteractionId);
  // console.debug('Current case interaction as string: ' + JSON.stringify(caseInteractionStore.currentCaseInteraction));

  // set stores
  // TODO: proper reset functions !
  const patientInteractionStore = usePatientInteractionStore();

  await patientInteractionStore.reset();

  await Promise.all([
    patientInteractionStore.setCaseInteraction(caseInteractionStore.currentCaseInteraction),
    // TODO: where is the 3rd person interaction?
  ]);

  if (!!originChapterId.value) {
    await router.push({
      path: '/case-interactions/' + caseInteractionStore.currentCaseInteractionId,
      query: {
        originChapterId: originChapterId.value,
      },
    });
  } else {
    await router.push('/case-interactions/' + caseInteractionStore.currentCaseInteractionId);
  }
  console.debug('finished onStartCaseClick()');

  isSubmitting.value = false;
}

const firstTask = computed(() => {
  return currentCase.value?.tasks[0];
});
</script>

<template>
  <div v-show="!currentCase" class="mt-48">
    <LoadingSpinnerLarge />
  </div>
  <div v-show="!!currentCase" class="w-screen h-full flex items-center justify-center">
    <div class="px-2 my-auto items-center gap-x-2 gap-y-6 flex-col flex">
      <div
        class="flex-col flex border border-gray-200 max-h-96 gap-y-2 overflow-y-auto bg-white shadow-sm px-3 py-4 mt-1 justify-center items-start-ms-px rounded-lg text-sm focus:z-10 text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white"
      >
        <p class="text-sm font-semibold text-gray-800 dark:text-neutral-200">
          {{ firstTask?.title }}
        </p>
        <p class="text-sm text-gray-500 dark:text-neutral-500">
          {{ !!firstTask ? firstTask.details.description : '' }}
        </p>
        <p>
          <span class="text-gray-500 dark:text-gray-400 font-normal">Aufgaben:</span>
        </p>
        <ul class="py-0 px-4 space-y-0.5">
          <li v-for="(subtask, subtask_index) in firstTask?.subtasks">
            <div class="inline-flex items-center gap-x-3 text-sm text-gray-500 dark:text-neutral-400">
              <span translate="no" class="material-symbols-outlined notranslate text-xl">
                <span translate="no" class="material-symbols-outlined notranslate"> target </span>
              </span>
              {{ subtask.details.target }}
            </div>
          </li>
        </ul>
      </div>

      <div class="rounded-lg shadow-sm shadow-gray-400">
        <!-- shadow made darker here to visually match that of toggle group -->
        <ProgressButton
          :onclick="onStartCaseClick"
          text="Jetzt starten"
          :showProgress="isSubmitting"
          type="button"
          :disabled="isLoading"
        />
      </div>
      <!-- TODO i18n -->

      <div>
        <InteractionInputToggleGroup
          :showDetailedLabels="true"
          :showLanguageLevelSelect="true"
          :showMinimalVersionWhenSmallScreen="false"
        />
      </div>
    </div>
  </div>
</template>

<style></style>
