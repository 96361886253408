<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onMounted, Ref, ref, watch } from 'vue';
import { formatDate, mapJobStatus, mapTitle } from '@/helper';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import TextEditor from '@/views/courses/TextEditor.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import SelectCasesModal from '@/components/SelectCasesModal.vue';
import { v4 as uuidv4 } from 'uuid';

import { useI18n } from 'vue-i18n';
import { DEBUG } from '@/helper/debugging';
import SectionContent from '@/views/courses/PageContent.vue';
import { debounce } from 'lodash';
import { onBeforeUnmount } from 'vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import Badge from '@/components/Badge.vue';
import { HSTooltip } from 'preline';
import Tooltip from '@/components/Tooltip.vue';
import PageContent from '@/views/courses/PageContent.vue';
import PageCreateCaseTask from '@/views/courses/PageCreateCaseTask.vue';
import PageCreateExercise from '@/views/courses/PageCreateExercise.vue';
import PageCreateVocabListAndTest from '@/views/courses/PageCreateVocabListAndTest.vue';
import PageMediaUpload from '@/views/courses/PageMediaUpload.vue';
import PageCreateTextItem from '@/views/courses/PageCreateTextItem.vue';
import EditCoursePageFooterContents from '@/views/courses/EditCoursePageFooterContents.vue';

const { t } = useI18n();

const props = defineProps([
  'pageContents',
  'sectionIndex',
  'sectionId',
  'pageIndex',
  'chapterId',
  'chapterIndex',
  'outerHeaderHeight',
  'fullWidth',
  'showNative',
  'pageLoaded',
  'currentScrollHeight',
  'fetchingSectionContentsCompleted',
]);

const emit = defineEmits([
  'onDeletePage',
  'restoreScrollPosition',
  'pageLoaded',
  'scrollToBottom',
  'scrollTo',
  'refetchSectionContents',
]);

const caseList = ref();
const media = ref([] as any[]);
const pairOfTermsGames = ref([] as any[]);
const uuid = ref(uuidv4());

// ref for components
const aboutToDeleteTextItemAtIndex = ref([] as boolean[]);
const aboutToDeleteContentItemWithId = ref(null as string | null);
const aboutToDeletePage = ref(false);
const titleEditor = ref<InstanceType<typeof TextEditor> | null>(null);
const pageContentComponent = ref<InstanceType<typeof SectionContent> | null>(null);

const isAddingContentItem = ref(false);
const outerContentContainer = ref(null);
const gap = ref(50);
const padding = ref(100);

const vocabListLoading = ref(false);

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const alertStore = useAlertStore();
const courseStore = useCourseStore();
const userConformationModalOverwriteLearningObjectives = ref();
const userConformationModalHandleDeleteTextItem = ref();
const userConformationModalHandleDeletePage = ref();
const selectCasesModal = ref(null);
const rememberedScrollPosition = ref(props.currentScrollPosition);
const sendingTranslationRequest = ref(false);
const sendingVocabularyExtractionRequest = ref(false);
const scrollHeightBeforeDelete = ref(0);

const { currentChapter } = storeToRefs(courseStore);

const internalFetchCompleted = ref(true);
const fetchCompleted = computed(() => {
  return props.fetchingSectionContentsCompleted && internalFetchCompleted.value;
});

const outerContentContainerWidth = computed(() => {
  return props.fullWidth - 2 * padding.value;
});

const internalPageIndex = computed(() => {
  // this ensures that we add new content for the correct DB-internal page number
  // even if not continuous enumeration - this is NOT guaranteed!
  // null / None will create new page
  return !!props.pageContents[0] ? props.pageContents[0].page_index : null;
});

const adjustPadding = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth', screenWidth);
  if (screenWidth < 640) {
    padding.value = 5;
  } else if (screenWidth < 1024) {
    padding.value = 10;
  }
  padding.value = Math.min(100, Math.round(screenWidth * 0.05));
};

const debounceAdjustPadding = debounce(adjustPadding, 100);
const computedContentForExtraction = ref('');

const computeTotalContent = () => {
  const title = titleEditor.value ? titleEditor.value.getHtmlContent() : '';

  if (!pageContentComponent.value) {
    return title;
  }
  let content = pageContentComponent.value.contentEditors
    .map((editor: any) => {
      return editor.value[0].getHtmlContent();
    })
    .join(' ');

  return title + ' ' + content;
};

const onExtractLearningObjectives = async () => {
  computedContentForExtraction.value = computeTotalContent();
  if (!pageContentComponent.value?.learningObjectives) {
    console.error('null ref in learningObjectives');
    return;
  }
  if (!computedContentForExtraction.value) {
    // empty content, nothing to do
    return;
  }
  userConformationModalOverwriteLearningObjectives.value.promptUserConformation().catch((error: any) => {
    console.debug('User declined: ' + error);
    return false;
  });
};

const extractLearningObjectives = async () => {
  if (!pageContentComponent.value?.learningObjectives) {
    console.error('null ref in learningObjectives');
    return;
  }
  pageContentComponent.value.learningObjectives.extractAndSetLearningObjectives(computedContentForExtraction.value);
};

const section = computed(() => {
  if (!currentChapter.value || !currentChapter.value.sections || props.sectionIndex == null) {
    return null;
  }
  return currentChapter.value.sections[props.sectionIndex];
});

const isPublished = computed(() => {
  return section.value?.published_at != null;
});

onBeforeMount(async () => {
  emit('pageLoaded', props.pageIndex, false, null);
  await courseStore.settingCoursePromise;
  await courseStore.settingChapterPromise; // only mount after chapter is loaded to store
  // console.log('section: ' + JSON.stringify(section));
});

onMounted(async () => {
  await nextTick(() => {
    HSTooltip.autoInit();
  });

  if (!section.value) {
    throw new Error('Section not found');
  }

  adjustPadding();
  window.addEventListener('resize', debounceAdjustPadding);

  await setEditorRefs();
  emit('pageLoaded', props.pageIndex, true, props.pageContents.length > 0);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceAdjustPadding);
});

watch(
  () => fetchCompleted.value,
  // this watcher stores the current scroll when fetchCompleted is set to false (so fetching new stuff),
  // and restores it when back to true
  async (newValue: boolean) => {
    if (newValue) {
      console.log('fetch completed');
      await nextTick();
      emit('restoreScrollPosition', rememberedScrollPosition.value);
    } else {
      rememberedScrollPosition.value = props.currentScrollPosition;
    }
  },
);

const onTranslateSection = () => {
  // TODO i18n
  sendingTranslationRequest.value = true;
  if (!section.value) {
    return;
  }
  courseStore
    .requestSectionTranslations(section.value.id, 'deu', ['eng', 'rus', 'aeb', 'pes'])
    .then(() => {
      alertStore.success('Übersetzungsauftrag wurde erfolgreich gestartet.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Starten des Übersetzungsauftrags: ' + error);
    })
    .finally(() => {
      sendingTranslationRequest.value = false;
    });
};

const onDeleteContentItem = async (contentItemId: string, content_item_type: string, textItemIndex: number) => {
  if (!section.value) {
    return;
  }

  aboutToDeleteContentItemWithId.value = contentItemId;
  if (content_item_type === 'TEXT') {
    aboutToDeleteTextItemAtIndex.value[textItemIndex] = true; // okay if nextTick before open
  }
  await userConformationModalHandleDeleteTextItem.value.promptUserConformation();
};

const deleteContentItem = async () => {
  if (!section.value) {
    return;
  }
  if (!aboutToDeleteContentItemWithId.value) {
    return;
  }
  scrollHeightBeforeDelete.value = props.currentScrollHeight;
  internalFetchCompleted.value = false;
  await courseStore
    .removeContentItem(section.value.id, aboutToDeleteContentItemWithId.value)
    .then(() => {
      alertStore.success('Gelöscht.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Löschen>: ' + error);
    })
    .finally(async () => {
      await setEditorRefs(); // do not forget!
      aboutToDeleteTextItemAtIndex.value = aboutToDeleteTextItemAtIndex.value.map(() => false); // all to false
      internalFetchCompleted.value = true;
      await nextTick();
      emit('scrollTo', scrollHeightBeforeDelete.value);
    });
};

const addTextSnippet = async (designation: string = 'GENERAL') => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addTextSnippet(section.value.id, internalPageIndex.value, designation)
    .then(async () => {
      alertStore.success('Textabschnitt erfolgreich hinzugefügt.');
    })
    .catch((error) => {
      alertStore.error('Fehler beim Hinzufügen des Textabschnitts: ' + error);
      throw Error('Error adding text snippet ', error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addPairOfTermsGame = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addPairOfTermsGame(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('Zuordnungsübung erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Zuordnungsübung: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addClassificationExercise = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addClassificationExercise(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('Klassifikationsübung erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Klassifikationsübung: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addCloze = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addCloze(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('Lückentext erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen des Lückentexts: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addMcQuestion = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addMcQuestion(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('MC-Frage erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen einer MC-Frage: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addOpenQuestion = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addOpenQuestion(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('Offene Frage erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen einer offenen Frage: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addContentWithQuestions = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addContentWithQuestions(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('Inhalt für offene und MC-Fragen erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen: ' + error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const onDeletePage = () => {
  if (!section.value) {
    return;
  }
  if (!userConformationModalHandleDeletePage.value) {
    return;
  }
  aboutToDeletePage.value = true;
  userConformationModalHandleDeletePage.value.promptUserConformation().catch((error: any) => {
    console.debug('Error: ' + error);
    return false;
  });
};

const deletePage = () => {
  console.log('deleting page');
  alertStore.info('Noch nicht implementiert - todo.');
  aboutToDeletePage.value = false;
};

const onAddCase = () => {
  if (!section.value) {
    return;
  }
  if (!selectCasesModal.value) {
    return;
  }
  selectCasesModal.value.promptSelection().catch((error: any) => {
    alertStore.error('Fehler beim Öffnen der Fallauswahl: ' + error);
    return false;
  });
};

const addCases = async (selectedCaseIds: string[]) => {
  console.log('add cases: ' + selectedCaseIds);
  if (!section.value?.id) {
    return;
  }
  if (!selectedCaseIds.length) {
    return;
  }
  selectedCaseIds.map((caseId) => {
    courseStore
      .addCase(section.value.id, caseId, internalPageIndex.value)
      .then(() => {
        alertStore.success('Fall erfolgreich hinzugefügt.');
        emit('scrollToBottom');
      })
      .catch((error: any) => {
        alertStore.error('Fehler beim Hinzufügen des Falls ' + caseId + ': ' + error);
      });
  });
};

const setEditorRefs = async () => {
  if (!section.value) {
    return;
  }
  if (!section.value.section_content_items) {
    return;
  }
  if (!pageContentComponent.value) {
    return;
  }
  await pageContentComponent.value.setEditorRefs();
  if (section.value.section_content_items) {
    // TODO check if these indices still okay after section => pages
    aboutToDeleteTextItemAtIndex.value.length = 0;
    for (let contentItem of section.value.section_content_items) {
      if (contentItem.content_type === 'TEXT') {
        aboutToDeleteTextItemAtIndex.value.push(false);
      }
    }
  }
  await nextTick();
};

const getLearningObjectives = () => {
  return learningObjectives.value.getLearningObjectives();
};

const generateAndAddMoreVocab = async (contentItemId: string) => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  vocabListLoading.value = true;
  // append all text items via ' ' to get a single string
  const context = pageContentComponent.value?.contentEditors
    .map((editor: any) => {
      return editor.value[0].getHtmlContent();
    })
    .join(' ');
  courseStore
    .generateAndAddMoreVocab(section.value.id, contentItemId, context)
    .then(() => {
      alertStore.success('Vokabeln hinzugefügt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Erzeugen weiterer Vokabeln: ' + error);
      throw Error('Error adding more vocab ', error);
    })
    .finally(async () => {
      vocabListLoading.value = false;
      await nextTick();
      emit('scrollToBottom');
    });
};

const addVocabListExtractingFromLastTextItem = async () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  // get last text item
  const textToExtractFrom =
    pageContentComponent.value?.contentEditors[
      pageContentComponent.value?.contentEditors.length - 1
    ].value[0].getHtmlContent();
  courseStore
    .addVocabListFromExtractions(section.value.id, textToExtractFrom, internalPageIndex.value)
    .then(() => {
      alertStore.success('Vokabelliste erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Vokabelliste: ' + error);
      throw Error('Error adding vocab list thru extract (last) ', error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addVocabListExtractingFromAllTextItems = async () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  // append all text items via ' ' to get a single string
  const textToExtractFrom = pageContentComponent.value?.contentEditors
    .map((editor: any) => {
      return editor.value[0].getHtmlContent();
    })
    .join(' ');
  courseStore
    .addVocabListFromExtractions(section.value.id, textToExtractFrom, internalPageIndex.value)
    .then(() => {
      alertStore.success('Vokabelliste erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Vokabelliste: ' + error);
      throw Error('Error adding vocab list thru extract (all) ', error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addEmptyVocabList = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addEmptyVocabList(section.value.id, internalPageIndex.value)
    .then(() => {
      alertStore.success('Vokabelliste erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Vokabelliste: ' + error);
      throw Error('Error adding vocab list', error);
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addFormCaseExercise = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addFormCaseExercise(
      section.value.id,
      'Sprich mit dem Patienten und erhebe den Barthel-Index. ' +
        'Du findest das Formular im KIS (unten links) unter dem Reiter "Formulare".',
      internalPageIndex.value,
    )
    .then(() => {
      alertStore.success('Doku-Übung erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Fall-Doku-Übung: ' + error);
      throw Error('Error adding case form exercise');
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addFormStructuredExercise = () => {
  if (isAddingContentItem.value || !fetchCompleted.value) {
    return;
  }
  if (!section.value) {
    return;
  }
  isAddingContentItem.value = true;
  internalFetchCompleted.value = false;
  courseStore
    .addFormStructuredExercise(
      section.value.id,
      'Lies dir das Gespräch durch und dokumentiere den Barthel-Index des Patienten auf dem Formular. ' +
        'Achte darauf, es vollständig auszufüllen!',
      '74-jährige Patientin zur Revision Hüft-TEP, Aufnahme aus der Häuslichkeit, alleinstehen, kein Pflegedienst',
      internalPageIndex.value,
    )
    .then(() => {
      alertStore.success('Doku-Übung erfolgreich angelegt.');
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Anlegen der Doku-Übung: ' + error);
      throw Error('Error adding structured form exercise');
    })
    .finally(async () => {
      isAddingContentItem.value = false;
      internalFetchCompleted.value = true;
      await setEditorRefs();
      await nextTick();
      emit('scrollToBottom');
    });
};

const addFormCaseTask = () => {
  console.log('adding case task');
};

const publishSection = (publish: boolean) => {
  console.log('publishing: ' + publish);
  if (!section.value) {
    return;
  }
  courseStore
    .publishSection(section.value.id, publish)
    .then(() => {
      alertStore.success(
        publish
          ? 'Lehreinheit ist jetzt für Kursteilnehmer:innen sichtbar.'
          : 'Lehreinheit ist jetzt vor Kursteilnehmer:innen verborgen.',
      );
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Veröffentlichen/ Verbergen: ' + error);
      throw Error('Error publishing section', error);
    });
};

const footerExpanded = ref(false);
const footerInView = ref(false);
const collapsibleFooter = ref(null);
const staticFooter = ref(null);

// Use Intersection Observer to detect when we'd naturally reach the footer
onMounted(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        footerInView.value = true;
      } else {
        footerInView.value = false;
      }
    },
    {
      threshold: 0.1,
      rootMargin: '100px', // Trigger slightly before we reach it
    },
  );

  if (staticFooter.value) {
    observer.observe(staticFooter.value);
  }

  onBeforeUnmount(() => {
    observer.disconnect();
  });
});

defineExpose({
  titleEditor,
  getLearningObjectives,
});
</script>

<template>
  <!-- Blog Article -->
  <div class="flex w-full justify-center items-center mx-auto" v-if="!fetchCompleted">
    <div
      class="px-1 pt-6 lg:pt-10 pb-12 sm:px-4 lg:px-8 bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-900 dark:border-gray-700 flex justify-center"
      :style="{ width: props.fullWidth + 'px' }"
    >
      <LoadingSpinnerLarge />
    </div>
  </div>
  <div
    v-show="fetchCompleted"
    class="flex flex-col mx-auto transition-all duration-500 ease-in-out pt-6 lg:pt-10 pb-12 bg-white border border-gray-200 rounded-xl shadow-sm overflow-visible dark:bg-neutral-900 dark:border-gray-700 justify-center"
    :style="{ width: props.fullWidth + 'px' }"
  >
    <div class="grow transition-all duration-500 ease-in-out">
      <div class="mx-auto flex justify-between items-center mb-6" :style="{ width: outerContentContainerWidth + 'px' }">
        <div class="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
          <div class="grow">
            <div class="flex justify-end items-center gap-x-2">
              <!-- Button Group -->
              <!-- End Button Group -->

              <div class="flex-col flex justify-between items-center gap-x-2 overflow-auto">
                <div v-if="!!section" class="inline-flex justify-between">
                  <div>
                    <!-- Tooltip -->
                    <div class="hs-tooltip inline-block [--trigger:hover] [--placement:bottom]">
                      <div class="hs-tooltip-toggle sm:mb-1 block text-start cursor-pointer">
                        <span>
                          <span class="text-xs text-gray-500"> by </span>
                          <span class="font-semibold text-gray-800 dark:text-gray-200">
                            {{ section.user.academic_title ? mapTitle(section.user.academic_title) + ' ' : ''
                            }}{{ section.user.first_name }}
                            {{ section.user.last_name }}
                          </span>
                        </span>
                        <div class="text-xs text-gray-500">
                          {{ section.user.job_status ? mapJobStatus(section.user.job_status) : '' }}
                        </div>
                        <span
                          class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-white font-medium text-xs"
                          role="tooltip"
                        >
                          Author of this section
                          <!-- TODO i18n -->
                          <!-- TODO proper profile card or sth alike -->
                        </span>
                      </div>
                    </div>
                    <!-- End Tooltip -->
                    <ul class="text-xs text-gray-500">
                      <li
                        class="inline-block relative pe-6 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-2 before:-translate-y-1/2 before:size-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600"
                      >
                        {{ formatDate(section.created_at) }}
                      </li>
                    </ul>
                  </div>
                  <div class="flex-shrink-0">
                    <ProfileImage
                      :image="section.user.userProfileImageSmall"
                      initials=""
                      size="4rem"
                      :showIngameLevel="false"
                    />
                  </div>
                </div>

                <div class="inline-flex w-full justify-between items-center gap-x-2 overflow-auto">
                  <!-- Publish -->
                  <!-- currently, this is only passively signalling if parent section is published -->
                  <div class="w-full mx-auto flex justify-end items-center gap-x-2 mt-6">
                    <div class="inline-flex justify-between items-center w-full">
                      <div class="font-semibold text-gray-800 dark:text-gray-200">
                        Status
                        <Tooltip
                          message="Alle Lernbausteine eines Kursabschnitts werden mit diesem und gemeinsam veröffentlicht oder verborgen."
                        />
                      </div>
                      <div class="group">
                        <div class="ml-4 pb-0.5 block" v-if="!isPublished">
                          <Badge text="Unpublished draft" color="gray" :showDot="true" fontSize="text-sm" />
                        </div>
                        <div class="ml-4 pb-0.5 block" v-if="isPublished">
                          <Badge text="Unit is live" color="green" :showDot="true" :pulse="true" fontSize="text-sm" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- TODO let author/ teacher publish individual pages one by one -->
                  <!--                  <div class="w-full mx-auto flex justify-end items-center gap-x-2 mt-6">-->
                  <!--                    <div class="inline-flex justify-between items-center w-full">-->
                  <!--                      <div class="font-semibold text-gray-800 dark:text-gray-200">-->
                  <!--                        Status-->
                  <!--                        <Tooltip-->
                  <!--                          message="Klicken zum Veröffentlichen der Lerneinheit.-->
                  <!--                            Veröffentlichte Lerneinheiten sind für alle Nutzer:innen sichtbar, die-->
                  <!--                            Zugriff auf den entsprechenden Kurs haben - für alle anderen natürlich nicht!"-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                      <div class="group cursor-pointer" @click.prevent="publishSection(!isPublished)">-->
                  <!--                        <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="!isPublished">-->
                  <!--                          <Badge text="Unpublished draft" color="gray" :showDot="true" fontSize="text-sm" />-->
                  <!--                        </div>-->
                  <!--                        <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="!isPublished">-->
                  <!--                          <Badge text="Publish draft" color="blue" :showDot="false" fontSize="text-sm" icon="publish" />-->
                  <!--                        </div>-->
                  <!--                        <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="isPublished">-->
                  <!--                          <Badge text="Lesson is live" color="green" :showDot="true" :pulse="true" fontSize="text-sm" />-->
                  <!--                        </div>-->
                  <!--                        <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="isPublished">-->
                  <!--                          <Badge-->
                  <!--                            text="Hide lesson"-->
                  <!--                            color="blue"-->
                  <!--                            :showDot="false"-->
                  <!--                            :pulse="true"-->
                  <!--                            icon="hide_source"-->
                  <!--                            fontSize="text-sm"-->
                  <!--                          />-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <!-- End Avatar Media -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div
        class="mx-auto flex space-y-5 transition-all duration-500 ease-in-out md:space-y-8"
        ref="outerContentContainer"
        :style="{ width: outerContentContainerWidth + 'px' }"
      >
        <div
          class="space-y-3"
          :style="{
            gap: `${gap}px`,
          }"
        >
          <PageContent
            v-if="!!section"
            ref="pageContentComponent"
            :key="section.id"
            :allow-editing="true"
            :content-items="props.pageContents"
            :chapter-id="props.chapterId"
            :section-id="props.sectionId"
            :section-index="props.sectionIndex"
            :outer-width="outerContentContainerWidth"
            :about-to-delete-content-item-with-id="aboutToDeleteContentItemWithId"
            :about-to-delete-text-item-at-index="aboutToDeleteTextItemAtIndex"
            :external-fetch-completed="fetchCompleted"
            :outer-header-height="props.outerHeaderHeight"
            :vocab-list-loading="vocabListLoading"
            @onExtractLearningObjectives="onExtractLearningObjectives"
            @onDeleteContentItem="
              (contentItemId, content_item_type, textItemIndex) =>
                onDeleteContentItem(contentItemId, content_item_type, textItemIndex)
            "
            @generateAndAddMoreVocab="
              (contentItemId) => {
                generateAndAddMoreVocab(contentItemId);
              }
            "
          />
        </div>
      </div>

      <!-- standard footer, static, alawys exapnaded -->
      <div ref="staticFooter" class="bg-gray-100 rounded-lg p-0.5 sm:p-2">
        <EditCoursePageFooterContents
          v-if="!!section"
          :footer-expanded="true"
          :footer-in-view="true"
          :section-id="section.id"
          :page-index="props.pageIndex"
          :outer-header-height="props.outerHeaderHeight"
          id="static-footer"
          @addTextSnippet="addTextSnippet"
          @refetchSectionContents="emit('refetchSectionContents')"
          @addPairOfTermsGame="addPairOfTermsGame"
          @addEmptyVocabList="addEmptyVocabList"
          @addVocabListExtractingFromLastTextItem="addVocabListExtractingFromLastTextItem"
          @addVocabListExtractingFromAllTextItems="addVocabListExtractingFromAllTextItems"
          @addClassificationExercise="addClassificationExercise"
          @addCloze="addCloze"
          @addMcQuestion="addMcQuestion"
          @addOpenQuestion="addOpenQuestion"
          @addContentWithQuestions="addContentWithQuestions"
          @onAddCase="onAddCase"
          @addFormStructuredExercise="addFormStructuredExercise"
          @addFormCaseExercise="addFormCaseExercise"
        />
      </div>

      <!-- Sticky footer copy (collapsible, hidden when static and always expanded footer in view) -->
      <div
        v-if="false"
        ref="collapsibleFooter"
        v-show="!footerInView"
        class="px-1 pt-3 z-30 md:px-2 lg:px-8 xl:px-24 fixed bottom-2"
        :style="{
          width: fullWidth + 'px',
        }"
      >
        <div class="bg-gray-100 rounded-lg p-0.5 sm:p-2">
          <div
            class="inline-flex w-full justify-start items-center cursor-pointer"
            @click="
              () => {
                if (!footerInView) {
                  footerExpanded = !footerExpanded;
                }
              }
            "
          >
            <p class="text-base text-justify text-gray-800 dark:text-gray-200 font-semibold pt-4 mb-4">
              Einen Inhalt am Ende des Lernbausteins hinzufügen
            </p>
            <span
              translate="no"
              class="no-translate select-none material-symbols-outlined transition-transform duration-300 ml-2"
              :class="{
                'rotate-0': footerExpanded,
                'rotate-180': !footerExpanded,
              }"
            >
              expand_more
            </span>
          </div>

          <EditCoursePageFooterContents
            v-if="!!section"
            :footer-expanded="footerExpanded"
            :footer-in-view="footerInView"
            :section-id="section.id"
            :page-index="props.pageIndex"
            :outer-header-height="props.outerHeaderHeight"
            id="collapsible-footer"
            @addTextSnippet="addTextSnippet"
            @refetchSectionContents="emit('refetchSectionContents')"
            @addPairOfTermsGame="addPairOfTermsGame"
            @addEmptyVocabList="addEmptyVocabList"
            @addVocabListExtractingFromLastTextItem="addVocabListExtractingFromLastTextItem"
            @addVocabListExtractingFromAllTextItems="addVocabListExtractingFromAllTextItems"
            @addClassificationExercise="addClassificationExercise"
            @addCloze="addCloze"
            @addMcQuestion="addMcQuestion"
            @addOpenQuestion="addOpenQuestion"
            @addContentWithQuestions="addContentWithQuestions"
            @onAddCase="onAddCase"
            @addFormStructuredExercise="addFormStructuredExercise"
            @addFormCaseExercise="addFormCaseExercise"
          />
        </div>
      </div>

      <!-- End content -->
    </div>
    <!-- End Blog Article -->

    <UserConfirmationModal
      ref="userConformationModalHandleDeletePage"
      prompt_message=""
      approve_message="Ja, löschen"
      discard_message="Nein, zurück"
      :overlayId="'confirmDeletePageModal' + uuid"
      approve_color="bg-red-600 hover:bg-red-700"
      @approved="deletePage"
      @declined="aboutToDeletePage = false"
    >
      Möchten Sie wirklich die gesamte Seite / Lesson mit allen Inhalten entfernen? Dies kann nicht rückgängig gemacht
      werden!
    </UserConfirmationModal>

    <UserConfirmationModal
      ref="userConformationModalHandleDeleteTextItem"
      prompt_message=""
      approve_message="Ja, löschen"
      discard_message="Nein, zurück"
      :overlayId="'confirmDeleteTextItemModal' + uuid"
      approve_color="bg-red-600 hover:bg-red-700"
      @approved="deleteContentItem"
      @declined="
        () => {
          aboutToDeleteContentItemWithId = null;
          aboutToDeleteTextItemAtIndex = aboutToDeleteTextItemAtIndex.map(() => false);
        }
      "
    >
      Möchten Sie diesen Inhalt wirklich entfernen? Dies kann nicht rückgängig gemacht werden.
    </UserConfirmationModal>

    <UserConfirmationModal
      ref="userConformationModalOverwriteLearningObjectives"
      prompt_message=""
      approve_message="Ja, neu Lernziele generieren und überschreiben"
      discard_message="Nein, zurück"
      :overlayId="'confirmOverwriteLearningObjectives' + uuid"
      @approved="extractLearningObjectives"
    >
      Wenn Sie die Lernziele automatisch extrahieren lassen, werden die bisherigen Lernziele für diesen Abschnitt
      überschrieben. Möchten Sie fortfahren?
    </UserConfirmationModal>

    <!--  <UserConfirmationModal-->
    <!--    ref="userConformationModalOverwriteVocab"-->
    <!--    prompt_message=""-->
    <!--    approve_message="Ja, neu Vokabular extrahieren und überschreiben"-->
    <!--    discard_message="Nein, zurück"-->
    <!--    :overlayId="'confirmOverwriteVocab' + uuid"-->
    <!--    @approved="extractVocabulary"-->
    <!--    @declined=""-->
    <!--  >-->
    <!--    Wenn Sie das Vokabular automatisch extrahieren lassen, wird das aktuelle Vokabular für diesen Abschnitt inklusive-->
    <!--    möglicher Überarbeitungen überschrieben. Möchten Sie fortfahren?-->
    <!--  </UserConfirmationModal>-->

    <SelectCasesModal
      v-if="!!section"
      :overlayId="'selectCases' + uuid"
      ref="selectCasesModal"
      closeMessage="Schließen"
      :sectionId="section.id"
      @selected="addCases"
    />
  </div>

  <!-- Bottom button group -->
  <div class="inset-x-0 text-center justify-center flex gap-x-2 mb-4" v-if="!!fetchCompleted">
    <button
      class="flex items-center shadow-md text-white bg-red-600 hover:bg-red-700 rounded-full py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      @click="onDeletePage"
    >
      <span translate="no" class="material-symbols-outlined notranslate text-2xl -my-1">delete</span>
      {{ $t('message.deleteLesson') }}
    </button>
    <button
      class="flex items-center shadow-md rounded-full cursor-pointer text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"
      @click="onTranslateSection"
      :disabled="sendingTranslationRequest"
    >
      <span translate="no" class="material-symbols-outlined notranslate text-xl" v-show="!sendingTranslationRequest"
        >language</span
      >
      <span
        v-show="sendingTranslationRequest"
        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
        role="status"
        aria-label="loading"
      />
      Übersetzung erzeugen
      <!--      <Tooltip text="Der Auftrag wird über Nacht durchgeführt" />-->
      <!-- TODO: i18n -->
    </button>
    <!--    <button-->
    <!--      class="flex items-center shadow-md rounded-full cursor-pointer text-gray-800 bg-white hover:bg-gray-50 py-3 pl-4 pr-6 text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium gap-x-2"-->
    <!--      @click="onExtractVocabulary"-->
    <!--      :disabled="sendingVocabularyExtractionRequest"-->
    <!--    >-->
    <!--      <span translate="no" class="material-symbols-outlined notranslate text-xl" v-show="!sendingVocabularyExtractionRequest">dictionary</span>-->
    <!--      <span-->
    <!--        v-show="sendingVocabularyExtractionRequest"-->
    <!--        class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"-->
    <!--        role="status"-->
    <!--        aria-label="loading"-->
    <!--      />-->
    <!--      Vokabular extrahieren-->
    <!--      &lt;!&ndash;      <Tooltip text="Der Auftrag wird über Nacht durchgeführt" />&ndash;&gt;-->
    <!--      &lt;!&ndash; TODO: i18n &ndash;&gt;-->
    <!--    </button>-->
  </div>
  <!-- End Bottom Button Group -->
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
