<script setup lang="ts">
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import TextEditor from '@/views/courses/TextEditor.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import CaseHighlightCard from '@/components/CaseHighlightCard.vue';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import PairOfTerms from '@/components/didactics/pair_of_terms/PairOfTerms.vue';
import { ref, defineProps, defineEmits, computed, PropType, watch, onMounted } from 'vue';
import { nextTick, Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAlertStore, useAuthStore, useCourseStore } from '@/stores';
import { debounce } from 'lodash';
import DOMPurify from 'dompurify';
import { toRef } from 'vue';
import VocabExercise from '@/components/didactics/vocab/VocabExercise.vue';
import CategorizationExercise from '@/components/didactics/categorization/CategorizationExercise.vue';
import FormStructuredExercise from '@/components/didactics/form_structured_exercise/FormStructuredExercise.vue';
import FormCaseExercise from '@/components/didactics/form_case_exercise/FormCaseExercise.vue';
import Cloze from '@/components/didactics/cloze/Cloze.vue';
import ResourceWithQuestions from '@/components/didactics/resource_with_questions/ResourceWIthQuestions.vue';
import TextItem from '@/components/didactics/text/TextItem.vue';

const props = defineProps({
  contentItems: {
    type: Array,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  chapterId: {
    type: String,
    required: true,
  },
  allowEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  aboutToDeleteContentItemWithId: {
    type: [String, null] as PropType<string | null>, // can be null or String
    required: false,
  },
  aboutToDeleteTextItemAtIndex: {
    type: Array,
    required: false,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  externalFetchCompleted: {
    type: Boolean,
    required: true,
  },
  showNative: {
    type: Boolean,
    required: false,
  },
  outerWidth: {
    type: Number,
    required: true,
  },
  vocabListLoading: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits([
  'signalUnsavedChangesLearningObjectives',
  'onExtractLearningObjectives',
  'onDeleteContentItem',
  'showNativeToggled',
  'generateAndAddMoreVocab',
  'scrollBy',
  'undoLastScroll',
]);

const contentEditors = ref<Array<Ref<HTMLElement | null>>>([]);
const fullWidthIndex = ref(-1);
const fullHeightIndex = ref(-1);

// refs for translation column
const reactiveShowNative = toRef(props, 'showNative');
const reactiveOuterWidth = toRef(props, 'outerWidth');
const germanContentContainers = ref<Array<Ref<HTMLElement | null>>>([]); // Array of refs for each text item container
const pairOfTermsGameContainers = ref([]);
const germanContentContainerHeights = ref([]); // Array for each item’s height
const authStore = useAuthStore();
const { userNativeLanguage } = storeToRefs(authStore);
const courseStore = useCourseStore();
const alertStore = useAlertStore();

// computes for translation column
const gap = computed(() => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth', screenWidth);
  if (screenWidth < 640) {
    return 8;
  } else if (screenWidth < 1024) {
    return 20;
  }
  return Math.min(50, Math.round(screenWidth * 0.025));
});

const relativeCharacterLength = computed(() => {
  if (userNativeLanguage.value === 'aeb') return 0.85;
  if (userNativeLanguage.value === 'pes') return 0.85;
  if (userNativeLanguage.value === 'rus' || userNativeLanguage.value === 'tgk') return 1.1;
  return 1.0;
});

const sanitizedSectionContentsHtml = computed(() => {
  return textContentItems.value.map((item) => DOMPurify.sanitize(item.content));
});

const translationAvailable = computed(() => {
  // TODO this needs to be re-implemented for text items
  return textContentItems.value.map(
    (item) => false,
    // !!props.section.content_translations
    // && !!props.section.content_translations[userNativeLanguage.value],
  );
});

const germanContainerWidths = computed(() => {
  return sanitizedSectionContentsHtml.value.map((content, index) => {
    if (!props.outerWidth) return 0;
    const translatedContent = translatedContentIfAny.value[index];
    if (!(content.length + translatedContent.length)) return 0;

    if (!reactiveShowNative.value) {
      return props.outerWidth;
    }
    if (!translationAvailable.value[index]) {
      // marginal column to display 'no translation available'
      return 0.7 * props.outerWidth;
    }
    if (content.length === 0) {
      return 0.7 * props.outerWidth;
    }
    // show German original and translation side by side
    return Math.floor(
      ((props.outerWidth - 2 * gap.value) * content.length) /
        (content.length + translatedContent.length * relativeCharacterLength.value),
    );
  });
});

const translatedContentIfAny = computed(() => {
  return textContentItems.value.map((item) => {
    if (!textContentItems.value.content_translations) return 'No translation available';
    const translation = textContentItems.value.content_translations[userNativeLanguage.value];
    return translation ? DOMPurify.sanitize(translation) : 'No translation available';
  });
});

const nativeContainerInnerWidths = computed(() => {
  return germanContainerWidths.value.map((germanWidth) => {
    if (!props.outerWidth) return 0;
    return props.outerWidth - 2 * gap.value - germanWidth;
  });
});

const nativeContainerOuterWidths = computed(() => {
  return nativeContainerInnerWidths.value.map((innerWidth) => innerWidth + gap.value);
});

const boxReducedWidth = computed(() => {
  // Check if the window width is greater than the MD breakpoint
  return window.innerWidth > 768 ? 200 : 40;
});

const toggleFullWidth = (index: number) => {
  if (fullWidthIndex.value === index) {
    fullWidthIndex.value = -1;
  } else {
    fullWidthIndex.value = index;
  }
};

// functions for translation column
const getHeights = () => {
  germanContentContainers.value.forEach((container, index) => {
    if (!container.value) {
      console.log(`germanContentContainer for index ${index} is not set. Ok if unmounting.`);
      return;
    }
    germanContentContainerHeights.value[index] = container.value[0].offsetHeight;
  });
  pairOfTermsGameContainers.value.forEach((container, index) => {
    if (!container.value) {
      console.log(`pairOfTermsGameContainer for index ${index} is not set. Ok if unmounting.`);
      return;
    }
    console.log(container.value[0].maximizedHeight);
    // pairOfTermsGameContainerMaximizedHeights.value[index] = container.value[0].maximizedHeight;
  });
};

const nativeContainerTranslations = computed(() => {
  return germanContainerWidths.value.map((germanWidth, index) => {
    if (!reactiveShowNative.value) {
      return props.outerWidth;
    }

    // Translation width considering the width with the native language shown
    return props.outerWidth - nativeContainerOuterWidths.value[index];
  });
});

// const getWidth = () => {
//   const screenWidth = window.innerWidth;
//   widthWithNativeShown.value = Math.max(1024, Math.round(screenWidth * 0.75)); // % of screen width with minimum
//   widthWithoutNativeShown.value = Math.max(1024, Math.round(screenWidth * 0.62));
// };

const fetchCompleted = computed(() => {
  return props.externalFetchCompleted;
});

const debounceGetHeights = debounce(getHeights, 100);

watch(reactiveShowNative, () => {
  debounceGetHeights();
});

watch(reactiveOuterWidth, () => {
  debounceGetHeights();
});

watch(fetchCompleted, () => {
  debounceGetHeights();
});

const textContentItems = computed(() => {
  return props.contentItems
    .filter((contentItem) => contentItem.content_type === 'TEXT')
    .map((contentItem) => contentItem.text_item);
});

const textItemIndex = (index: number) => {
  // map index in props.contentItems to index in textContentItems
  return textContentItems.value.findIndex((textItem) => textItem.id === props.contentItems[index].text_item.id);
};

const pairOfTermsGameContentItems = computed(() => {
  return props.contentItems
    .filter((contentItem) => contentItem.content_type === 'PAIR_OF_TERMS_GAME')
    .map((contentItem) => contentItem.pair_of_terms_game);
});

const pairOfTermsGameItemIndex = (index: number) => {
  // map index in props.contentItems to index in textContentItems
  return pairOfTermsGameContentItems.value.findIndex(
    (item) => item.id === props.contentItems[index].pair_of_terms_game.id,
  );
};

// functions for editing mode
const setEditorRefs = async () => {
  if (!props.contentItems) {
    return;
  }
  if (props.contentItems) {
    contentEditors.value.length = 0;
    germanContentContainers.value.length = 0;
    for (let contentItem of props.contentItems) {
      if (contentItem.content_type === 'TEXT') {
        contentEditors.value.push(ref<HTMLElement | null>(null));
        germanContentContainers.value.push(ref<HTMLElement | null>(null));
      }
    }
  }
  console.log('Now holding # content editors: ' + contentEditors.value.length);
  await nextTick();
};

const setPairOfTermsGameRefs = async () => {
  console.log('Setting pair of terms game refs');
  if (!props.contentItems) {
    return;
  }
  pairOfTermsGameContainers.value.length = 0;
  for (let contentItem of props.contentItems) {
    if (contentItem.content_type === 'PAIR_OF_TERMS_GAME') {
      pairOfTermsGameContainers.value.push(ref(null));
    }
  }
  console.log('Now holding # pair of terms game containers: ' + pairOfTermsGameContainers.value.length);
  await nextTick();
};

// const saveLearningObjectives = async () => {
//   if (!props.allowEditing) return;
//   courseStore
//     .updateSection(props.sectionId, {
//       learningObjectives: learningObjectives.value.getLearningObjectives(),
//     })
//     .then(() => {
//       unsavedChangesLearningObjectives.value = false;
//     })
//     .catch((error) => {
//       console.error(error);
//       alertStore.error('Failed to autosave title', 'Error', error);
//       throw new Error('Failed to autosave title');
//     });
// };

onMounted(async () => {
  await setPairOfTermsGameRefs();
  await setEditorRefs();
  getHeights();
});

const resetTextEditorEmitStates = async () => {
  // wait 10 ms
  await new Promise((resolve) => setTimeout(resolve, 10));
  console.log('length of contentEditors: ' + contentEditors.value.length);
  contentEditors.value.forEach((editor) => {
    console.log(editor);
    console.log(editor.value);
  });
  contentEditors.value.forEach((editor) => {
    editor.value[0].resetEmitState();
  });
};

defineExpose({
  setEditorRefs,
  setPairOfTermsGameRefs,
  resetTextEditorEmitStates,
  contentEditors,
});
</script>

<template>
  <!-- Content -->
  <div class="w-full mx-auto grid grid-flow-row grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-6 gap-y-24 pb-3">
    <div
      v-for="(contentItem, index) in props.contentItems"
      :key="contentItem.id"
      class="transition-all auto-rows-min duration-500 ease-in-out"
      :class="{
        'col-span-2 md:col-span-3': contentItem.content_type !== 'MEDIA' && contentItem.content_type !== 'CASE',
        'col-span-2 md:col-span-3 overflow-hidden':
          (contentItem.content_type === 'MEDIA' || contentItem.content_type === 'CASE') && fullWidthIndex === index,
        'col-span-1':
          (contentItem.content_type === 'MEDIA' || contentItem.content_type === 'CASE') && fullWidthIndex !== index,
      }"
    >
      <div
        v-if="contentItem.content_type === 'TEXT'"
        class="grid grid-rows-1 max-h-fit mx-auto h-fit divide-gray-400 justify-between transition-all duration-500 ease-in-out overflow-hidden"
        :key="!!contentItem.text_item ? contentItem.text_item.id : 'invalid'"
        :style="{
          width: `${props.outerWidth - (contentItem.text_item.designation !== 'GENERAL' ? boxReducedWidth : 0)}px`,
        }"
        :class="{
          'text-xs md:text-sm': showNative,
          'text-sm': !showNative,
          'bg-white ': contentItem.designation === 'GENERAL',
          'bg-amber-200 rounded-lg border border-amber-500 px-2 ': contentItem.text_item.designation === 'GRAMMAR_BOX',
          'bg-indigo-300 rounded-lg border border-indigo-500 px-2 ':
            contentItem.text_item.designation === 'LEARNING_STRATEGY_BOX',
          'bg-violet-200 rounded-lg border border-violet-500 px-2 ':
            contentItem.text_item.designation === 'CULTURE_BOX',
          'bg-fuchsia-200 rounded-lg border border-fuchsia-500 px-2  ':
            contentItem.text_item.designation === 'CLINICAL_PRACTICE_BOX',
        }"
      >
        <!-- GERMAN -->
        <div
          :ref="germanContentContainers[textItemIndex(index)]"
          class="col-start-1 row-start-1 overflow-hidden text-justify text-gray-800 dark:text-gray-200 pb-10 relative rounded-lg flex-col flex"
          :class="{
            'border-red-600 bg-red-100': aboutToDeleteTextItemAtIndex
              ? aboutToDeleteTextItemAtIndex[textItemIndex(index)]
              : false,
          }"
          :style="{
            width: `${
              germanContainerWidths[textItemIndex(index)] -
              (contentItem.text_item.designation !== 'GENERAL' ? boxReducedWidth : 0)
            }px`,
          }"
        >
          <span
            v-if="contentItem.text_item.designation === 'GRAMMAR_BOX'"
            class="text-sm uppercase pt-1 pb-2 text-amber-800"
          >
            📚 Grammatik
          </span>
          <span
            v-else-if="contentItem.text_item.designation === 'LEARNING_STRATEGY_BOX'"
            class="text-sm uppercase pt-1 pb-2 text-indigo-800"
          >
            🤓 Lern-Tipp
          </span>
          <span
            v-else-if="contentItem.text_item.designation === 'CULTURE_BOX'"
            class="text-sm uppercase pt-1 pb-2 text-violet-800"
          >
            🇩🇪 Land & Leute
          </span>
          <span
            v-else-if="contentItem.text_item.designation === 'CLINICAL_PRACTICE_BOX'"
            class="text-sm uppercase pt-1 pb-2 text-fuchsia-800"
          >
            🏥 Klinik-Tipp
          </span>
          <TextItem
            :contentItem="contentItem"
            :sectionIndex="props.sectionIndex"
            :isEditing="props.allowEditing"
            :germanContainerWidth="germanContainerWidths[textItemIndex(index)]"
            :boxReducedWidth="boxReducedWidth"
          />
          <RemoveButton
            v-if="props.allowEditing"
            @removeItem="emit('onDeleteContentItem', contentItem.id, 'TEXT', contentItem.index)"
            :top="false"
            :box-positioning="contentItem.text_item.designation !== 'GENERAL'"
          />
        </div>
        <!-- NATIVE -->
        <div
          class="col-start-1 row-start-1 inline-flex text-justify items-justify text-gray-400 dark:text-gray-200 transition-all duration-500 ease-in-out overflow-hidden"
          :style="{
            height: showNative ? `auto` : `${germanContentContainerHeights[textItemIndex(index)]}px`,
            transform: `translateX(${nativeContainerTranslations[textItemIndex(index)]}px)`,
            width: `${
              nativeContainerOuterWidths[textItemIndex(index)] -
              (contentItem.text_item.designation !== 'GENERAL' ? boxReducedWidth : 0)
            }px`,
            gap: `${gap}px`,
          }"
        >
          <p class="border-s border-s-gray-100"></p>
          <p :style="{ width: `${nativeContainerInnerWidths[textItemIndex(index)]}px` }">
            <TextEditor :content="translatedContentIfAny[textItemIndex(index)]" :allowEdit="false" />
          </p>
        </div>
      </div>

      <div class="w-full h-full" v-else-if="contentItem.content_type === 'MEDIA'" :key="index">
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <MediaHighlightCard
          v-if="fetchCompleted"
          :contentItemId="contentItem.id"
          :url="contentItem.media_item.media_url"
          :type="contentItem.media_item.media_item_type"
          :description="contentItem.media_item.description"
          :allowEdit="props.allowEditing"
          :maxOnClick="true"
          :isMaximized="fullWidthIndex === index"
          @removeMediaItem="emit('onDeleteContentItem', contentItem.id, 'MEDIA', null)"
          @toggleFullWidth="toggleFullWidth(index)"
        />
        <button
          v-show="fullHeightIndex !== index"
          @click="
            (event) => {
              event.stopPropagation();
              fullHeightIndex = <number>index;
            }
          "
          class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
        >
          {{
            contentItem.media_item.media_item_type === 'IMAGE'
              ? 'Bild'
              : contentItem.media_item.media_item_type === 'VIDEO'
              ? 'Video'
              : contentItem.media_item.media_item_type === 'AUDIO'
              ? 'Audio'
              : 'Medieninhalt'
          }}
          (Klicken für Vollansicht)
        </button>
      </div>

      <div
        class="w-full md:w-2/3 mx-auto h-full border border-gray-200 rounded-lg relative"
        v-else-if="contentItem.content_type === 'PAIR_OF_TERMS_GAME'"
        :key="!!contentItem.pair_of_terms_game ? contentItem.pair_of_terms_game.id : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <PairOfTerms
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :allowEdit="props.allowEditing"
              :isMaximized="true"
              :fixedMaximization="true"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @scrollBy="(distance) => emit('scrollBy', distance)"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'PAIR_OF_TERMS_GAME', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
          <button
            v-show="false && fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Übung
            <!--            <span v-show="pairOfTermsGameContainerMaximizedHeights > 295">(Klicken zum Ausklappen)</span>-->
          </button>
        </div>
      </div>

      <div
        class="mx-auto h-full border border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'CATEGORIZATION_EXERCISE'"
        :key="!!contentItem.categorization_exercise ? contentItem.categorization_exercise.id : 'invalid'"
        :class="{
          'w-full md:w-2/3': false && fullHeightIndex !== index,
          'w-full': true || fullHeightIndex === index,
        }"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <CategorizationExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :allowEdit="props.allowEditing"
              :isMaximized="true"
              :fixedMaximization="true"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @scrollBy="(distance) => emit('scrollBy', distance)"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'CATEGORIZATION_EXERCISE', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
          <button
            v-show="false && fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Übung (Klicken zum Ausklappen)
          </button>
        </div>
      </div>

      <div
        class="mx-auto w-full h-full border border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'CLOZE'"
        :key="!!contentItem.cloze ? contentItem.cloze.id : 'invalid'"
      >
        <div class="flex-col flex">
          <div class="">
            <Cloze
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'CLOZE', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto w-full h-full border border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'RESOURCE_WITH_QUESTIONS'"
        :key="!!contentItem.resource_with_questions ? contentItem.resource_with_questions.id : 'invalid'"
      >
        <div class="flex-col flex">
          <div class="">
            <ResourceWithQuestions
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
              @scrollBy="(distance) => emit('scrollBy', distance)"
              @undoLastScroll="emit('undoLastScroll')"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'RESOURCE_WITH_QUESTIONS', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto h-full w-full border border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'FORM_CASE'"
        :key="!!contentItem.form_structured_exercise ? contentItem.form_structured_exercise.id : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <FormCaseExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :sectionIndex="<number>props.sectionIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'CATEGORIZATION_EXERCISE', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
        </div>
      </div>

      <div
        class="mx-auto h-full w-full border border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'FORM_STRUCTURED_EXERCISE'"
        :key="!!contentItem.form_structured_exercise ? contentItem.form_structured_exercise.id : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="">
            <FormStructuredExercise
              v-if="fetchCompleted"
              :contentItem="contentItem"
              :index="<number>index"
              :sectionIndex="<number>props.sectionIndex"
              :isEditing="<boolean>props.allowEditing"
              :key="!!contentItem.updateTimestamp ? contentItem.updateTimestamp : contentItem.id"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'CATEGORIZATION_EXERCISE', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
        </div>
      </div>

      <!--           v-else-if="contentItem.content_type === 'VOCAB_LIST'" -->
      <!--          :key="contentItem.vocab_list.id" -->
      <div
        class="mx-auto h-full border border-gray-200 rounded-lg relative transition-all transition-1000 ease-in-out"
        v-else-if="contentItem.content_type === 'VOCAB_LIST'"
        :class="{
          'w-full md:w-2/3': false && fullHeightIndex !== index,
          'w-full': true || fullHeightIndex === index,
        }"
        :key="!!contentItem ? contentItem.id + '-vocab' : 'invalid'"
      >
        <!--                {{ contentItem.id }} @ {{ index }}-->
        <div class="flex-col flex">
          <div class="" v-if="fetchCompleted">
            <VocabExercise
              :contentItem="contentItem"
              :index="<number>index"
              :showNative="props.showNative"
              :isMaximized="true"
              :fixedMaximization="true"
              :allowEditing="props.allowEditing"
              :outerHeaderHeight="props.outerHeaderHeight"
              :vocab-list-loading="props.vocabListLoading"
              @viewFullHeightOn="fullHeightIndex = <number>index"
              @viewFullHeightOff="fullHeightIndex = -1"
              @generateAndAddMoreVocab="emit('generateAndAddMoreVocab', contentItem.id)"
              @scrollBy="(distance) => emit('scrollBy', distance)"
            />
            <RemoveButton
              v-if="props.allowEditing"
              @removeItem="emit('onDeleteContentItem', contentItem.id, 'VOCAB_LIST', null)"
              :top="false"
              :halfDistance="true"
            />
          </div>
          <button
            v-show="false && fullHeightIndex !== index"
            @click="
              (event) => {
                event.stopPropagation();
                fullHeightIndex = <number>index;
              }
            "
            class="pointer-events-auto bg-transparent w-full mx-auto text-center z-10 text-sm text-gray-500 dark:text-gray-400"
          >
            Vokabeln (Klicken zum Ausklappen & Üben)
          </button>
        </div>
      </div>

      <div
        class="mx-auto w-full h-full flex-col flex relative"
        v-else-if="contentItem.content_type === 'CASE'"
        :key="contentItem.case.id"
      >
        <div class="z-0">
          <CaseHighlightCard
            v-if="fetchCompleted"
            :case="contentItem.case"
            :sectionId="<string>props.sectionId"
            :chapterId="<string>props.chapterId"
            rounded="rounded-xl"
            @removeItem="emit('onDeleteContentItem', contentItem.id, 'CASE', null)"
            :allowRemoveCaseFromSection="props.allowEditing"
            :allowEditCase="false"
            :allowCopyCase="false"
            :allowViewCase="true"
          />
        </div>
        <label class="w-full mx-auto text-center text-sm text-gray-500 dark:text-gray-400"
          >Fall-Simulation (Klicken zum Starten)</label
        >
      </div>
    </div>
  </div>
</template>

<style scoped></style>
