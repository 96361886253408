<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, nextTick, watch } from 'vue';
import { useAlertStore, useAuthStore } from '@/stores';
import { getApiClient } from '@/apiclient/client';
import { storeToRefs } from 'pinia';
import AudioPlayerTooltip from '@/components/AudioPlayerTooltip.vue';
import ContextMenuTooltip from '@/components/ContextMenuTooltip.vue';

const alertStore = useAlertStore();
const authStore = useAuthStore();

const { vocabLists } = storeToRefs(authStore);
const isVisible = ref(false);
const position = ref({ x: 0, y: 0 });
const selectedText = ref('');
const context = ref('');
const vocabItem = ref();
const tooltipStack = ref([]);

const showExplanationTooltip = ref(false);
const showAudioPlayerTooltip = ref(false);
const showExamplesTooltip = ref(false);
const showUsageExamplesTooltip = ref(false);

const isLoadingExplanation = ref(false);
const isLoadingAddFavorite = ref(false);
const isLoadingExamples = ref(false);
const isLoadingAudio = ref(false);
const isLoadingUsageExamples = ref(false);

const audioTooltip = ref(null);
const explanationTooltip = ref(null);
const examplesTooltip = ref(null);
const usageExamplesTooltip = ref(null);
const contextMenuContainer = ref(null);

const isLoading = computed(
  () => isLoadingExplanation.value || isLoadingAddFavorite.value || isLoadingAudio.value || isLoadingExamples.value,
);

// Method to copy the selected text
const copyText = () => {
  navigator.clipboard.writeText(selectedText.value);
  isVisible.value = false; // Hide the menu after action
};

// Method to search the selected text
const searchText = () => {
  const query = encodeURIComponent(selectedText.value);
  window.open(`https://www.google.com/search?q=${query}`, '_blank');
  isVisible.value = false;
};

watch(
  () => showAudioPlayerTooltip.value,
  async (value) => {
    await nextTick();
    if (value) {
      tooltipStack.value.push(audioTooltip.value);
    } else {
      tooltipStack.value.length = 0;
      if (showExplanationTooltip.value) {
        tooltipStack.value.push(explanationTooltip.value);
      }
      if (showExamplesTooltip.value) {
        tooltipStack.value.push(examplesTooltip.value);
      }
      if (showUsageExamplesTooltip.value) {
        tooltipStack.value.push(usageExamplesTooltip.value);
      }
    }
  },
);

watch(
  () => showExplanationTooltip.value,
  async (value) => {
    await nextTick();
    if (value) {
      tooltipStack.value.push(explanationTooltip.value);
    } else {
      tooltipStack.value.length = 0;
      if (showAudioPlayerTooltip.value) {
        tooltipStack.value.push(audioTooltip.value);
      }
      if (showExamplesTooltip.value) {
        tooltipStack.value.push(examplesTooltip.value);
      }
      if (showUsageExamplesTooltip.value) {
        tooltipStack.value.push(usageExamplesTooltip.value);
      }
    }
  },
);

watch(
  () => showExamplesTooltip.value,
  async (value) => {
    await nextTick();
    if (value) {
      tooltipStack.value.push(examplesTooltip.value);
    } else {
      tooltipStack.value.length = 0;
      if (showAudioPlayerTooltip.value) {
        tooltipStack.value.push(audioTooltip.value);
      }
      if (showExplanationTooltip.value) {
        tooltipStack.value.push(explanationTooltip.value);
      }
      if (showUsageExamplesTooltip.value) {
        tooltipStack.value.push(usageExamplesTooltip.value);
      }
    }
  },
);

watch(
  () => showUsageExamplesTooltip.value,
  async (value) => {
    await nextTick();
    if (value) {
      tooltipStack.value.push(usageExamplesTooltip.value);
    } else {
      tooltipStack.value.length = 0;
      if (showAudioPlayerTooltip.value) {
        tooltipStack.value.push(audioTooltip.value);
      }
      if (showExplanationTooltip.value) {
        tooltipStack.value.push(explanationTooltip.value);
      }
      if (showExamplesTooltip.value) {
        tooltipStack.value.push(examplesTooltip.value);
      }
    }
  },
);

const audioPlayerTooltipPositionInStack = computed(() => tooltipStack.value.indexOf(audioTooltip.value));
const explanationTooltipPositionInStack = computed(() => tooltipStack.value.indexOf(explanationTooltip.value));
const examplesTooltipPositionInStack = computed(() => tooltipStack.value.indexOf(examplesTooltip.value));
const usageExamplesTooltipPositionInStack = computed(() => tooltipStack.value.indexOf(usageExamplesTooltip.value));

const moveAudioPlayerTooltipToEndOfStack = () => {
  // if already at end of stack: close it
  if (audioPlayerTooltipPositionInStack.value === tooltipStack.value.length - 1) {
    showAudioPlayerTooltip.value = false;
    return;
  }
  // console.log('moveAudioPlayerTooltipToEndOfStack');
  tooltipStack.value = tooltipStack.value.filter((item) => item !== audioTooltip.value);
  tooltipStack.value.push(audioTooltip.value);
};

const moveExplanationTooltipToEndOfStack = () => {
  // if already at end of stack: close it
  if (explanationTooltipPositionInStack.value === tooltipStack.value.length - 1) {
    showExplanationTooltip.value = false;
    return;
  }
  // console.log('moveExplanationTooltipToEndOfStack');
  tooltipStack.value = tooltipStack.value.filter((item) => item !== explanationTooltip.value);
  tooltipStack.value.push(explanationTooltip.value);
};

const moveExamplesTooltipToEndOfStack = () => {
  // if already at end of stack: close it
  if (examplesTooltipPositionInStack.value === tooltipStack.value.length - 1) {
    showExamplesTooltip.value = false;
    return;
  }
  // console.log('moveExamplesTooltipToEndOfStack');
  tooltipStack.value = tooltipStack.value.filter((item) => item !== examplesTooltip.value);
  tooltipStack.value.push(examplesTooltip.value);
};

const moveUsageExamplesTooltipToEndOfStack = () => {
  // if already at end of stack: close it
  if (examplesTooltipPositionInStack.value === tooltipStack.value.length - 1) {
    showUsageExamplesTooltip.value = false;
    return;
  }
  // console.log('moveUsageExamplesTooltipToEndOfStack');
  tooltipStack.value = tooltipStack.value.filter((item) => item !== usageExamplesTooltip.value);
  tooltipStack.value.push(usageExamplesTooltip.value);
};

// Show the context menu with the selected text and set position
const showContextMenu = (x: any, y: any) => {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);

  if (range && selection) {
    // Get the selected text
    selectedText.value = selection.toString();

    if (selectedText.value) {
      // Find the sentence around the selection
      const startNode = range.startContainer;
      const endNode = range.endContainer;

      const getSentence = (node, offset) => {
        const text = node.textContent || '';
        // Extract text before and after the selection
        const beforeMatch = text.slice(0, offset).match(/[^.!?]*[.!?]?$/);
        const afterMatch = text.slice(offset).match(/^[^.!?]*[.!?]?/);

        const before = beforeMatch ? beforeMatch[0] : '';
        const after = afterMatch ? afterMatch[0] : '';

        return before + after;
      };

      context.value = getSentence(startNode, range.startOffset);

      position.value = { x, y };
      isVisible.value = true;
    }
  }
};

const borderSavePosition = computed(() => {
  let x = position.value.x;
  let y = position.value.y;
  const rect = contextMenuContainer.value?.getBoundingClientRect();
  if (!rect) {
    return { x, y };
  }

  const { innerWidth, innerHeight } = window;

  let width = 200;
  let height = 150;

  if (x + width > innerWidth) {
    x = innerWidth - 2 * width;
  }

  if (y + height > innerHeight) {
    y = innerHeight - 2 * height;
  }

  return { x, y };
});

// Hide the context menu
const hideContextMenu = () => {
  // console.log('hideContextMenu');
  showAudioPlayerTooltip.value = false;
  showExplanationTooltip.value = false;
  showExamplesTooltip.value = false;
  showUsageExamplesTooltip.value = false;
  isVisible.value = false;
  tooltipStack.value.length = 0;
};

const createVocabItem = async () => {
  let statusOk = false;
  console.log('Create vocab item - vocab: ', selectedText.value, 'usage_examples[length]: ', context.value);
  await (
    await getApiClient()
  ).vocabs
    .createVocabItemFromContext({
      term: selectedText.value,
      context: context.value,
    })
    .then((response) => {
      vocabItem.value = response;
      statusOk = true;
    })
    .catch((error) => {
      alertStore.error('Failed to access or create vocab item', 'Error', error);
    });
  return statusOk;
};

const explainVocabItem = async () => {
  isLoadingExplanation.value = true;
  showExplanationTooltip.value = false;
  createVocabItem()
    .then(async (statusOk) => {
      if (!statusOk) {
        return;
      }
      showExplanationTooltip.value = true;
    })
    .finally(() => {
      isLoadingExplanation.value = false;
    });
};

const obtainVocabExamples = async () => {
  isLoadingExamples.value = true;
  showExamplesTooltip.value = false;
  alertStore.info('This feature will follow soon to give examples of any term!');
  isLoadingExamples.value = false;
  return;
  createVocabItem()
    .then(async (statusOk) => {
      if (!statusOk) {
        return;
      }
      showExamplesTooltip.value = true;
    })
    .finally(() => {
      isLoadingExamples.value = false;
    });
};

const obtainUsageExamples = async () => {
  isLoadingUsageExamples.value = true;
  showUsageExamplesTooltip.value = false;
  createVocabItem()
    .then(async (statusOk) => {
      if (!statusOk) {
        return;
      }
      showUsageExamplesTooltip.value = true;
    })
    .finally(() => {
      isLoadingUsageExamples.value = false;
    });
};

const addToFavorites = async () => {
  isLoadingAddFavorite.value = true;
  createVocabItem()
    .then(async (statusOk) => {
      if (!statusOk) {
        return;
      }
      console.log('add to favorites');
      await (
        await getApiClient()
      ).vocabLists
        .copyVocabToPersonalVocabList(
          vocabLists.value[1].id, // this is the id of the favorites list
          vocabItem.value.id,
        )
        .then((response) => {
          alertStore.success('Vocabulary added to your Wortschatzkiste');
        })
        .catch((error) => {
          alertStore.error('Failed to add vocab item to favorites', 'Error', error);
        });
    })
    .finally(() => {
      isLoadingAddFavorite.value = false;
    });
};

const readOut = async () => {
  isLoadingAudio.value = true;
  showAudioPlayerTooltip.value = false;
  createVocabItem()
    .then(async (statusOk) => {
      if (!statusOk) {
        return;
      }
      showAudioPlayerTooltip.value = true;
    })
    .finally(() => {
      isLoadingAudio.value = false;
    });
};

const handleClickOutside = (event: Event) => {
  if (!contextMenuContainer.value) {
    return;
  }
  if (!isVisible.value) {
    return;
  }
  if (!contextMenuContainer.value.contains(event.target)) {
    hideContextMenu();
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(async () => {
  showAudioPlayerTooltip.value = false;
  showExplanationTooltip.value = false;
  showExamplesTooltip.value = false;
  showUsageExamplesTooltip.value = false;
  document.removeEventListener('click', handleClickOutside);
});

defineExpose({
  showContextMenu,
  hideContextMenu,
});
</script>

<template>
  <div
    ref="contextMenuContainer"
    v-show="isVisible"
    :style="{ top: `${borderSavePosition.y}px`, left: `${borderSavePosition.x}px` }"
    class="fixed bg-white shadow-md p-2 rounded-lg border border-gray-200 z-50"
    @mousedown.stop
    @click.prevent="
      () => {
        if (!showExplanationTooltip && !showAudioPlayerTooltip) {
          hideContextMenu();
          return;
        }
        showExplanationTooltip = false;
        showAudioPlayerTooltip = false;
      }
    "
  >
    <!--    <button class="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100" @click="copyText">Copy</button>-->
    <!--    <button class="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100" @click="searchText">Search</button>-->
    <!--    -->
    <div class="p-1 space-y-0.5">
      <span class="block pt-2 pb-1 px-3 text-xs font-medium uppercase text-gray-400 dark:text-neutral-500">
        Vocabulary
      </span>
      <button
        @click.prevent="
          (event) => {
            event.stopPropagation();
            explainVocabItem();
          }
        "
        :disabled="isLoading"
        class="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
        :class="{
          'text-gray-400 dark:text-neutral-400 cursor-default': isLoading,
          'text-gray-800 dark:text-neutral-400 cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700':
            !isLoading,
        }"
      >
        <span translate="no" class="notranslate material-symbols-outlined"> lightbulb </span>
        Explain
        <span
          v-show="isLoadingExplanation"
          class="animate-spin inline-block w-4 h-4 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </button>
      <button
        @click.prevent="
          (event) => {
            event.stopPropagation();
            obtainVocabExamples();
          }
        "
        :disabled="isLoading"
        class="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
        :class="{
          'text-gray-400 dark:text-neutral-400 cursor-default': isLoading,
          'text-gray-800 dark:text-neutral-400 cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700':
            !isLoading,
        }"
      >
        <span translate="no" class="notranslate material-symbols-outlined"> category </span>
        Give me examples
        <span
          v-show="isLoadingExamples"
          class="animate-spin inline-block w-4 h-4 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </button>
      <button
        @click.prevent="
          (event) => {
            event.stopPropagation();
            obtainUsageExamples();
          }
        "
        :disabled="isLoading"
        class="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
        :class="{
          'text-gray-400 dark:text-neutral-400 cursor-default': isLoading,
          'text-gray-800 dark:text-neutral-400 cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700':
            !isLoading,
        }"
      >
        <span translate="no" class="notranslate material-symbols-outlined"> engineering </span>
        Show me how to use this term
        <span
          v-show="isLoadingUsageExamples"
          class="animate-spin inline-block w-4 h-4 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </button>
      <button
        @click.prevent="
          (event) => {
            event.stopPropagation();
            addToFavorites();
          }
        "
        :disabled="isLoading"
        class="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
        :class="{
          'text-gray-400 dark:text-neutral-400 cursor-default': isLoading,
          'text-gray-800 dark:text-neutral-400 cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700':
            !isLoading,
        }"
      >
        <span translate="no" class="notranslate material-symbols-outlined"> star </span>
        Add to my Wortschatzkiste
        <span
          v-show="isLoadingAddFavorite"
          class="animate-spin inline-block w-4 h-4 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </button>
    </div>
    <div class="p-1 space-y-0.5">
      <span class="block pt-2 pb-1 px-3 text-xs font-medium uppercase text-gray-400 dark:text-neutral-500">
        Pronounciation
      </span>
      <button
        @click.prevent="
          (event) => {
            event.stopPropagation();
            readOut();
          }
        "
        :disabled="isLoading"
        class="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm"
        :class="{
          'text-gray-400 dark:text-neutral-400 cursor-default': isLoading,
          'text-gray-800 dark:text-neutral-400 cursor-pointer hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700':
            !isLoading,
        }"
      >
        <span translate="no" class="notranslate material-symbols-outlined"> volume_up </span>
        Read out
        <span
          v-show="isLoadingAudio"
          class="animate-spin inline-block w-4 h-4 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full"
        />
      </button>
    </div>
  </div>
  <div
    v-if="showExplanationTooltip"
    ref="explanationTooltip"
    :style="{
      top: `${borderSavePosition.y + 20 * (explanationTooltipPositionInStack + 1)}px`,
      left: `${borderSavePosition.x + 20 * (explanationTooltipPositionInStack + 1)}px`,
    }"
    class="fixed bg-white max-w-2xl shadow-md rounded-lg border border-gray-200 z-50 text-xs md:text-sm"
    :class="`z-[${110 + explanationTooltipPositionInStack * 10}]`"
  >
    <ContextMenuTooltip
      :text="vocabItem.explanation"
      :isAtEndOfStack="explanationTooltipPositionInStack === tooltipStack.length - 1"
      @close="showExplanationTooltip = false"
      @moveToEndOfStack="moveExplanationTooltipToEndOfStack"
    />
  </div>
  <div
    v-if="showExamplesTooltip"
    ref="examplesTooltip"
    :style="{
      top: `${borderSavePosition.y + 20 * (examplesTooltipPositionInStack + 1)}px`,
      left: `${borderSavePosition.x + 20 * (examplesTooltipPositionInStack + 1)}px`,
    }"
    class="fixed bg-white max-w-2xl shadow-md rounded-lg border border-gray-200 z-50 text-xs md:text-sm"
    :class="`z-[${110 + examplesTooltipPositionInStack * 10}]`"
  >
    <ContextMenuTooltip
      :text="vocabItem.itemExamples"
      :isAtEndOfStack="examplesTooltipPositionInStack === tooltipStack.length - 1"
      :isList="true"
      @close="showExamplesTooltip = false"
      @moveToEndOfStack="moveExamplesTooltipToEndOfStack"
    />
  </div>
  <div
    v-if="showUsageExamplesTooltip"
    ref="usageExamplesTooltip"
    :style="{
      top: `${borderSavePosition.y + 20 * (usageExamplesTooltipPositionInStack + 1)}px`,
      left: `${borderSavePosition.x + 20 * (usageExamplesTooltipPositionInStack + 1)}px`,
    }"
    class="fixed bg-white max-w-2xl shadow-md rounded-lg border border-gray-200 z-50 text-xs md:text-sm"
    :class="`z-[${110 + usageExamplesTooltipPositionInStack * 10}]`"
  >
    <ContextMenuTooltip
      :text="vocabItem.usage_examples"
      :isAtEndOfStack="usageExamplesTooltipPositionInStack === tooltipStack.length - 1"
      :isList="true"
      @close="showUsageExamplesTooltip = false"
      @moveToEndOfStack="moveUsageExamplesTooltipToEndOfStack"
    />
  </div>
  <div
    v-if="showAudioPlayerTooltip"
    @click.prevent="
      (event) => {
        if (audioPlayerTooltipPositionInStack === tooltipStack.length - 1) {
          showAudioPlayerTooltip = false;
          return;
        }
        moveAudioPlayerTooltipToEndOfStack();
        event.stopPropagation();
      }
    "
    ref="audioTooltip"
  >
    <span />
    <!-- dummy -->
    <AudioPlayerTooltip
      :audio-url="vocabItem.audio_url"
      :term-to-display="vocabItem.term"
      :bodyClickCallback="
        (event) => {
          moveAudioPlayerTooltipToEndOfStack();
          event.stopPropagation();
        }
      "
      :position="{
        x: borderSavePosition.x + 20 * (audioPlayerTooltipPositionInStack + 1),
        y: borderSavePosition.y + 20 * (audioPlayerTooltipPositionInStack + 1),
      }"
      @close="
        (event) => {
          showAudioPlayerTooltip = false;
          event.stopPropagation();
        }
      "
      :class="`z-[${110 + audioPlayerTooltipPositionInStack * 10}]`"
      :autoplay="true"
    />
  </div>
</template>

<style scoped></style>
