<!--TODO
TODO: Send input to DB
TODO: Buttons for examination (Sthethoskop-Icon?) und Blutabnahme (Nadel-Icon?) neben "Senden"
TODO v1: scene desc from AI. Patient chat w/ AI
- v1.b: patient avatar
TODO v2: try therapy. Get updated scene desc as feedback.
TODO v3: user input suggestions? (toggle on/off?)

-->

<script setup>
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';

const emit = defineEmits(['startReport', 'playingAudio']);

import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useAuthStore, useCaseInteractionStore, usePatientInteractionStore } from '@/stores';

import ChatBubblePatient from '@/components/chat_bubbles/ChatBubblePatient.vue';
import ChatBubbleUser from '@/components/chat_bubbles/ChatBubbleUser.vue';
import ChatBubbleThirdPerson from '@/components/chat_bubbles/ChatBubbleThirdPerson.vue';
import SystemMessageFooter from '@/components/SystemMessageFooter.vue';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const patientInteractionStore = usePatientInteractionStore();
const { chatMessages: patientChatMessages, chatIsStreaming: patientChatIsStreaming } =
  storeToRefs(patientInteractionStore);
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const { chatMessages: thirdPersonChatMessages, anyChatIsStreaming: anyThirdPersonChatIsStreaming } =
  storeToRefs(thirdPersonInteractionStore);

// const chatMessages = ref([]);

const props = defineProps({
  selectedPerson: Object,
});

function stringNotEmptyOrNull(str) {
  return str !== null && str.trim() !== '';
}

async function loadHistoryOrInitNewThirdPersonInteraction(index) {
  // first we check if there is a history for this patient interaction
  const historyLoaded = await thirdPersonInteractionStore.fetchHistory(index);
  console.debug('history for 3rd persojn available and loaded: ' + historyLoaded);
  if (!historyLoaded) {
    // no history found, so we init the 3rd person interaction and start streaming
    await thirdPersonInteractionStore.getThirdPersonWelcomeMessage(index);
  }
}

function playingAudio(playing) {
  console.log('ChatHistory - Playing audio: ' + playing);
  emit('playingAudio', playing);
}

watch(
  () => props.selectedPerson,
  async (newPerson) => {
    console.log('History received new person: ', newPerson);
    if (!newPerson) {
      return;
    }
    console.log('selectedPerson ', newPerson, ' of type ', newPerson.type);
    if (newPerson.type === 'PATIENT') {
      return;
    } else if (newPerson.type === 'THIRD_PERSON') {
      return;
      // await loadHistoryOrInitNewThirdPersonInteraction(newPerson.thirdPersonIndex);
    } else {
      console.warn('selectedPerson of type ', newPerson.type, ' not recognized: ', newPerson);
      return;
    }
  },
);

const chatMessages = computed(() => {
  if (!props.selectedPerson) {
    return [];
  }
  console.log('selectedPerson ', props.selectedPerson, ' of type ', props.selectedPerson.type);
  if (props.selectedPerson.type === 'PATIENT') {
    return patientInteractionStore.chatMessages;
  } else if (props.selectedPerson.type === 'THIRD_PERSON') {
    return thirdPersonInteractionStore.chatMessages[props.selectedPerson.thirdPersonIndex];
  } else {
    console.warn('selectedPerson of type ', props.selectedPerson.type, ' not recognized: ', props.selectedPerson);
    return [];
  }
});
</script>

<template>
  <div class="grow">
    <!-- this fixes scroll to bottom; source: https://stackoverflow.com/a/72644230 -->
    <!-- part of the scroll stick -->
    <div v-if="!!props.selectedPerson" class="flex flex-col space-y-2" v-for="(message, index) in chatMessages">
      <!-- button show/ hide chat history; before last massage if more than two messages -->

      <!-- user + patient chat messages pairs -->
      <div v-if="message.type === 'SAY'" class="space-y-2 mt-2">
        <div v-if="stringNotEmptyOrNull(message.content['user_message'])">
          <ChatBubbleUser :message="message" conversation="PATIENT" />
        </div>
        <div>
          <ChatBubblePatient
            :message="message"
            :isLatestBubble="index === chatMessages.length - 1"
            @playingAudio="playingAudio"
          />
        </div>
      </div>
      <div v-else-if="message.type === 'REACT'" class="space-y-2 mt-2">
        <div>
          <ChatBubblePatient
            :message="message"
            :isLatestBubble="index === chatMessages.length - 1"
            @playingAudio="playingAudio"
          />
        </div>
      </div>
      <!-- user + 3rd person chat messages pairs -->
      <div v-else-if="message.type === 'THIRD_PERSON'" class="space-y-2 mt-2">
        <div v-if="stringNotEmptyOrNull(message.content['user_message'])">
          <ChatBubbleUser :message="message" conversation="PATIENT" />
        </div>
        <div>
          <ChatBubbleThirdPerson
            :message="message"
            :isLatestBubble="index === chatMessages.length - 1"
            :personIndex="selectedPerson.thirdPersonIndex"
            @playingAudio="playingAudio"
          />
        </div>
      </div>
      <div v-else-if="message.type === 'THIRD_PERSON_REACT'" class="space-y-2 mt-2">
        <div>
          <ChatBubbleThirdPerson
            :message="message"
            :isLatestBubble="index === chatMessages.length - 1"
            :personIndex="selectedPerson.thirdPersonIndex"
            @playingAudio="playingAudio"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
