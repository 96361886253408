<script setup lang="ts">
import ClozeEditor from '@/components/didactics/cloze/ClozeEditor.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { watch } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { getApiClient } from '@/apiclient/client';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { updateMinTextareaHeight } from '@/helper';
import { onBeforeRouteLeave } from 'vue-router';

const { t } = useI18n();

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

const alertStore = useAlertStore();
const courseInteractionStore = useCourseInteractionStore();
const courseStore = useCourseStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);

const localHtmlContent = ref(props.contentItem.cloze?.html_content);
const localTitle = ref(props.contentItem.cloze?.title);
const localTaskInstructions = ref(props.contentItem.cloze?.task_instructions);
const unsavedChanges = ref(false);
const clozeEditor = ref(null);
const interactionFetchCompleted = ref(false);
const itemInteractionState = ref({});

const nodeIsSolved = reactive({});

onMounted(async () => {
  await nextTick();
  if (props.isEditing) interactionFetchCompleted.value = true; // no need to wait for interaction fetch
  await fetchInteractionState();

  window.onbeforeunload = (e) => {
    if (unsavedChanges.value) {
      e.preventDefault();
      return true;
    }
  };
});

onBeforeUnmount(() => {
  if (unsavedChanges.value) {
    saveCloze();
  }
});

onBeforeRouteLeave(async (to, from, next) => {
  if (unsavedChanges.value) {
    try {
      await saveCloze();
      next(); // proceed with navigation after successful save
    } catch (error) {
      console.error('Failed to save:', error);
      // Ask user if they want to leave without saving
      const userWantsToLeave = window.confirm('Failed to save changes. Do you want to leave anyway?');
      if (userWantsToLeave) {
        next(); // proceed with navigation
      } else {
        next(false); // cancel navigation
      }
    }
  } else {
    next(); // no unsaved changes, proceed normally
  }
});

const allNodesSolved = computed(() => {
  return Object.values(nodeIsSolved).every((value) => value === true);
});

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(
    props.sectionIndex,
    props.contentItem.index,
  );
  // create local copy - we want to defer updates to wait for animations etc
  console.log(interactionState);
  if (!!interactionState && interactionState !== {}) {
    interactionFetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
    console.log(itemInteractionState.value);
  }
};

const saveCloze = async () => {
  if (!props.isEditing) return;
  await (
    await getApiClient()
  ).courseSectionItems
    .updateClozeItem(props.contentItem.id, {
      title: localTitle.value,
      task_instructions: localTaskInstructions.value,
      html_content: localHtmlContent.value,
    })
    .then(() => {
      unsavedChanges.value = false;
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave cloze', 'Error', error);
      throw new Error('Failed to autosave cloze');
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) {
        {
          if (!courseInteractionFetchCompleted.value) return;
          courseInteractionStore
            .startSectionContentItemIfNotAlready(props.sectionIndex, props.contentItem.index)
            .then((response) => {
              console.log('reopened_at', response.reopened_at);
              if (!response) return; // nothing has happened as the item was already started
              itemInteractionState.value = response;
            });
        }
      }
      setTimeout(() => {
        saveCloze();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

watch(
  () => props.contentItem.cloze?.html_content,
  (newValue) => {
    localHtmlContent.value = newValue;
  },
);

watch(
  () => allNodesSolved.value,
  (newValue) => {
    console.log('allNodesSolved', newValue);
    if (newValue) {
      courseInteractionStore
        .setSectionContentItemCompletedIfNotAlready(props.sectionIndex, props.contentItem.index, true)
        .then(async (response) => {
          console.log(response);
          if (!response) return;
          response.notifications?.forEach((notification) => {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          });
          itemInteractionState.value = response.section_content_item_interaction;
          await authStore.fetchUserXp();
        });
    }
  },
);

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    if (!props.isEditing) {
      await nextTick();
      await fetchInteractionState();
    }
  },
  { immediate: true },
);

const hideSolutions = () => {
  Object.keys(nodeIsSolved).forEach((nodeId) => {
    nodeIsSolved[nodeId] = false;
  });
  if (!clozeEditor.value) return;
  clozeEditor.value.resetAllAttempts();
};

const reopenExercise = async () => {
  hideSolutions();
  courseInteractionStore.reopenSectionContentItem(props.sectionIndex, props.contentItem.index).then((response) => {
    console.log(response);
    if (!response) return; // nothing has happened as the item was already started
    itemInteractionState.value = response;
  });
};
</script>

<template>
  <div class="w-full pt-0.5 px-1 pb-1 min-h-48 relative text-gray-800 dark:text-gray-200">
    <div
      :class="{
        'opacity-25':
          !props.isEditing &&
          (!interactionFetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <h2 class="text font-semibold text-center pt-2">Lückentext</h2>
      <h3 v-show="props.isEditing || localTitle !== ''" class="text text-center px-6">
        <textarea
          v-if="props.isEditing"
          v-model="localTitle"
          class="w-full px-2 py-0 mb-2 resize-none border-gray-200 rounded-lg text-center"
          placeholder="Titel (optional)"
          rows="1"
          @change="unsavedChanges = true"
        />
        <span v-else class="w-full px-2 my-4 rounded-lg text-center">{{ localTitle }}</span>
      </h3>
      <div
        v-show="props.isEditing || localTaskInstructions !== ''"
        class="text-justify py-2 w-full flex items-center text-xs md:text-sm"
      >
        <textarea
          v-if="props.isEditing"
          v-model="localTaskInstructions"
          class="w-full px-1 py-0 mb-2 text-xs md:text-sm resize-none border-gray-200 rounded-lg text-justify"
          placeholder="Aufgabenstellung (optional)"
          rows="2"
          @input="
            (event) => {
              const target = event.target as HTMLInputElement;
              updateMinTextareaHeight(target);
            }
          "
          @change="unsavedChanges = true"
        />
        <span v-else class="w-full px-0.5 my-4 text-xs md:text-sm rounded-lg flex text-justify">{{
          localTaskInstructions
        }}</span>
      </div>
      <div class="text-justify text-xs md:text-sm border border-gray-200 rounded-lg mb-12">
        <ClozeEditor
          ref="clozeEditor"
          :allowList="true"
          :allowEdit="props.isEditing"
          :content="contentItem.cloze.html_content"
          @unsavedChanges="
            (newHtml) => {
              localHtmlContent = newHtml;
              unsavedChanges = true;
            }
          "
          @nodeSolved="
            (nodeId, nodeSolved) => {
              console.log(nodeId, ' is solved: ', nodeSolved);
              nodeIsSolved[nodeId] = nodeSolved;
            }
          "
        />
      </div>
    </div>

    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!interactionFetchCompleted"
    >
      <LoadingSpinnerLarge />
    </div>

    <div
      v-if="
        interactionFetchCompleted &&
        !props.isEditing &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped></style>
