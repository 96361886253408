<script setup lang="ts">
// import lamejs from 'lamejs';  # v1.2.1: dependency not working. Known issue.

import { getApiClient } from '@/apiclient/client';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { storeToRefs } from 'pinia';

import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore } from '@/stores';
import { ref, computed, watch, onMounted } from 'vue';

import { SoundMeter } from '/src/audio/soundmeter.js';

const AUDIO_TYPE = 'audio';

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  reset: {
    default: false,
    type: Boolean,
  },
  mockProgress: {
    default: 0,
    type: Number,
  },
  size: {
    type: String,
    default: 'normal',
    validator: (value: string) => ['small', 'normal'].includes(value),
  },
});

const emit = defineEmits<{
  newTranscription: [text: string];
  transcriptionInProgress: [inProgress: boolean];
  resetComplete: [];
}>();

const caseInteractionStore = useCaseInteractionStore();
const alertStore = useAlertStore();
const { currentCaseInteractionLanguage } = storeToRefs(caseInteractionStore);

const volume = ref(0);

const buttonClasses = computed(() => ({
  'h-32 w-32 lg:h-44 lg:w-44': props.size === 'normal',
  'h-8 w-8': props.size === 'small',
  'cursor-not-allowed': props.disabled,
  'cursor-pointer': !props.disabled,
}));

const iconClasses = computed(() => ({
  'text-8xl md:text-7xl lg:text-8xl lg:-mt-2': props.size === 'normal',
  'text-3xl': props.size === 'small',
}));

const recorder = ref(null);
const stream = ref(null);
const isRecording = ref(false);
const isStopped = ref(true);
const isPaused = ref(false);
const transcribedText = ref('');
const pttStartTime = ref(null);
const pttStopTime = ref(null);
const minPttDuration = 500; // in ms

onMounted(() => {
  // console.log('Audio capabilities:', {
  //   hasMediaDevices: !!navigator.mediaDevices,
  //   hasGetUserMedia: !!navigator.mediaDevices?.getUserMedia,
  //   hasAudioContext: !!(window.AudioContext || window.webkitAudioContext),
  // });
});

async function toggleRecording() {
  if (isRecording.value) {
    await stopRecording();
  } else {
    await startRecording();
  }
}

async function startRecording() {
  if (props.disabled || isRecording.value) {
    return;
  }
  console.log('START');

  try {
    transcribedText.value = '';
    pttStartTime.value = new Date();
    isRecording.value = true;
    isStopped.value = false;
    stream.value = await navigator.mediaDevices.getUserMedia({ audio: true });
    recorder.value = new RecordRTCPromisesHandler(stream.value, {
      type: AUDIO_TYPE,
    });
    recorder.value.startRecording();

    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new AudioContext();
    } catch (e) {
      alert('Web Audio API not supported.');
      alertStore.error('Web Audio API not supported.', 'Error', e);
    }

    trackVolume(stream.value);
  } catch (error) {
    isRecording.value = false;
    isStopped.value = true;
    alertStore.error('Error 2', 'Error', error);
    throw new Error(`Error starting recording: ${error.message}`);
  }
  console.log('Recorder: ', recorder.value);
  console.log('As str: ', JSON.stringify(recorder.value));
  console.log('Stream', stream.value);
  console.log('As str: ', JSON.stringify(stream.value));
}

const trackVolume = (stream) => {
  const soundMeter = (window.soundMeter = new SoundMeter(window.audioContext));
  soundMeter.connectToSource(stream, function (e) {
    if (e) {
      alert(e);
      return;
    }
    let meterRefresh;
    meterRefresh = setInterval(() => {
      if (!window.soundMeter) {
        clearInterval(meterRefresh);
        return;
      }
      let vol = Number(soundMeter.instant.toFixed(2));
      volume.value = vol; // Make sure volume is initialized properly
    }, 50);
  });
};

async function stopRecording() {
  if (!isRecording.value) {
    return;
  }
  console.log('STOP');
  isRecording.value = false;
  isStopped.value = true;
  isPaused.value = false;
  pttStopTime.value = new Date();
  volume.value = 0.0;

  if (!recorder.value) {
    throw new Error('Cannot stop recording: no recorder');
  }
  try {
    await recorder.value.stopRecording();
    const blob = await recorder.value.getBlob();

    stream.value?.getTracks().forEach((track) => {
      track.stop();
    });
    recorder.value = null;
    stream.value = null;

    window.soundMeter.stop();
    window.soundMeter = null;
    await window.audioContext.close();

    if (pttStopTime.value - pttStartTime.value < minPttDuration) {
      console.log('Recording too short. Not sending.');
      alertStore.info('Mikrofon-Knopf gedrückt halten, um zu sprechen.');
      return;
    }
    await sendToBackend(blob, currentCaseInteractionLanguage.value || 'deu');
  } catch (error) {
    isRecording.value = false;
    isStopped.value = true;
    throw new Error(`Error stopping recording: ${error.message}`);
  }
}

async function sendToBackend(blob, case_language) {
  emit('transcriptionInProgress', true);
  await (
    await getApiClient()
  ).stt
    .speechToText(
      {
        audio: blob,
      },
      case_language,
    )
    .then((result) => {
      transcribedText.value = result;
      if (
        transcribedText.value === 'Untertitel der Amara.org-Community' ||
        transcribedText.value === 'Untertitel im Auftrag des ZDF für funk, 2017' ||
        transcribedText.value === 'Untertitelung aufgrund der Audioqualität nicht möglich'
      ) {
        console.warn('Transcription failed. Sending empty string.');
        transcribedText.value = '';
      }
      if (transcribedText.value.length > 0) {
        emit('newTranscription', transcribedText.value);
      }
    })
    .catch((error) => {
      console.error('Error in sendToBackend: ', error);
      alertStore.error('Error when transcribing audio.', 'Error', error);
    })
    .finally(() => {
      emit('transcriptionInProgress', false);
    });
}

// Watch reset prop
watch(
  () => props.reset,
  (newVal) => {
    if (newVal) {
      console.debug('Resetting audio recorder');
      transcribedText.value = '';
      emit('resetComplete'); // notify parent that reset is complete
    }
  },
);

// Computed properties for visual feedback
const showHalo = computed(() => {
  // Determine whether to show the halo based on volume
  return isRecording.value && volume.value > 0;
});

const haloSize = computed(() => {
  // Calculate halo size based on volume
  return `${Math.min(184, 112 + (156 - 112) * (volume.value * 10))}px`;
});

const haloScale = computed(() => {
  return `${Math.min(1.4, 1 + volume.value * 10)}`;
});

const haloInverseScale = computed(() => {
  return `${1.1 / Math.min(1.4, 1 + volume.value * 10)}`;
});
</script>

<template>
  <!-- Vol: {{ volume }}. -->
  <button
    class="group robust-button relative flex z-[90] items-center justify-center opacity-100 rounded-full select-none"
    draggable="false"
    :class="buttonClasses"
    @click.prevent=""
    @contextmenu.prevent
    @mousedown="startRecording"
    @mouseup="stopRecording"
    @touchstart.prevent="startRecording"
    @touchend.prevent="stopRecording"
    @touchcancel.prevent="stopRecording"
  >
    <div
      class="absolute flex z-[90] items-center justify-center bg-red-400/50 rounded-full"
      :class="{
        'h-24 w-24 lg:h-32 lg:w-32': size === 'normal',
        'h-8 w-8i': size === 'small',
      }"
      :style="{ transform: `scale(${haloScale})` }"
    >
      <div class="grid" :style="{ transform: `scale(${haloInverseScale})` }">
        <div
          class="col-start-1 row-start-1 relative items-center justify-center"
          :class="{
            'h-24 w-24 lg:h-32 lg:w-32': size === 'normal',
            'h-8 w-8': size === 'small',
          }"
        >
          <svg class="h-full w-full" width="40" height="40" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <!-- Background Circle -->
            <circle
              v-show="mockProgress > 0"
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current text-transparent shadow-none shadow-gray-400"
              stroke-width="4"
            ></circle>
            <!-- Progress Circle inside a group with rotation -->
            <g class="origin-center -rotate-90 transform">
              <circle
                v-show="mockProgress > 0"
                cx="18"
                cy="18"
                r="16"
                fill="none"
                class="stroke-current"
                :class="[
                  !disabled // if so: grey. Else: blue
                    ? !transcribedText // If filled: light blue. If not: dark blue (not recording) or red (recording)
                      ? isRecording
                        ? 'text-red-500 group-hover:text-red-600 shadow-inner shadow-none shadow-red-400 '
                        : 'text-blue-600 group-hover:text-blue-600 shadow-none shadow-gray-400  '
                      : 'text-blue-300 group-hover:text-blue-300 shadow-none shadow-gray-400  '
                    : 'text-gray-500 focus:ring-2 focus:ring-blue-600 focus:z-10 focus:outline-none shadow-none shadow-gray-400',
                ]"
                stroke-width="4"
                stroke-dasharray="100"
                :stroke-dashoffset="100 - mockProgress"
              ></circle>
            </g>
          </svg>
        </div>
        <!--        :style="{ width: haloSize, height: haloSize }"-->
        <div
          @click.prevent=""
          type="button"
          :style="{ transform: `scale(${1 / 1.1})` }"
          class="row-start-1 col-start-1 flex border-none flex-shrink-0 opacity-100 justify-center items-center rounded-full text-white"
          :class="[
            !disabled // if so: grey. Else: blue
              ? !transcribedText // If filled: light blue. If not: dark blue (not recording) or red (recording)
                ? isRecording
                  ? 'bg-red-500 group-hover:bg-red-600 shadow-inner shadow-none shadow-red-400 '
                  : 'bg-blue-600 group-hover:bg-blue-700 shadow-none shadow-gray-400  '
                : 'bg-blue-300 group-hover:bg-blue-300 shadow-none shadow-gray-400  '
              : 'bg-gray-500 cursor-not-allowed group-focus:ring-2 group-focus:ring-blue-600 focus:z-10 group-focus:outline-none shadow-none shadow-gray-400',
            mockProgress > 20 ? 'shadow-none' : '',
            size === 'normal' ? 'h-24 w-24 lg:h-32 lg:w-32' : '',
            size === 'small' ? 'h-8 w-8' : '',
          ]"
        >
          <div class="items-center opacity-100 group-hover:scale-100">
            <div class="items-center opacity-100">
              <div translate="no" class="material-symbols-outlined notranslate select-none" :class="iconClasses">
                mic
              </div>
            </div>
            <div
              class="items-center -mt-4 mb-2 hidden text-sm select-none"
              :class="{
                'lg:block': size === 'normal',
                hidden: size === 'small',
              }"
            >
              Push to talk
            </div>
            <div
              class="items-center -mt-2 mb-2 text-sm select-none"
              :class="{
                'hidden md:block lg:hidden': size === 'normal',
                hidden: size === 'small',
              }"
            >
              PTT
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<style>
@keyframes pulse {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.95;
  }
}

.animate-pulse {
  animation: pulse 50s infinite;
}

.robust-button {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* iOS-specific */
  -ms-user-select: none; /* IE/Edge */
  touch-action: manipulation; /* Prevent unwanted touch behaviors */
  pointer-events: auto; /* Ensure the button itself is still clickable */
}
</style>
