<script setup lang="ts">
import PageCreateTextItem from '@/views/courses/PageCreateTextItem.vue';
import PageMediaUpload from '@/views/courses/PageMediaUpload.vue';
import PageCreateVocabListAndTest from '@/views/courses/PageCreateVocabListAndTest.vue';
import PageCreateExercise from '@/views/courses/PageCreateExercise.vue';
import PageCreateCaseTask from '@/views/courses/PageCreateCaseTask.vue';
import { ref } from 'vue';

const props = defineProps({
  footerExpanded: {
    type: Boolean,
    required: true,
  },
  footerInView: {
    type: Boolean,
    required: true,
  },
  sectionId: {
    type: [String],
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'addTextSnippet',
  'refetchSectionContents',
  'addPairOfTermsGame',
  'addEmptyVocabList',
  'addVocabListExtractingFromLastTextItem',
  'addVocabListExtractingFromAllTextItems',
  'addClassificationExercise',
  'addCloze',
  'addMcQuestion',
  'addOpenQuestion',
  'addContentWithQuestions',
  'onAddCase',
  'addFormStructuredExercise',
  'addFormCaseExercise',
]);

const createVocabRef = ref(null);
</script>

<template>
  <div
    v-if="!!props.sectionId"
    class="grid grid-cols-5 sm:gap-2 md:gap-2 lg:gap-2 auto-rows-min overflow-hidden transition-all duration-300"
    :style="{
      maxHeight: footerExpanded || footerInView ? '500px' : '0',
      opacity: footerExpanded || footerInView ? '1' : '0',
    }"
  >
    <div v-show="!footerExpanded && !footerInView" class="flex-col flex opacity-0 w-[1px]">
      <!-- dummy element w/o click functionality for smooth collapse -->
      <PageCreateTextItem :section-id="sectionId" :outer-header-height="outerHeaderHeight" :parent-id="`${id}-dummy`" />
    </div>

    <div v-show="footerExpanded || footerInView" class="flex-col flex -ml-[1px]">
      <PageCreateTextItem
        :section-id="sectionId"
        :outer-header-height="outerHeaderHeight"
        :parent-id="id"
        @addTextSnippet="(designation) => emit('addTextSnippet', designation)"
      />
      <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Textabschnitt</label>
    </div>

    <div v-show="footerExpanded || footerInView" class="flex-col flex">
      <PageMediaUpload
        :section-id="sectionId"
        :page-index="pageIndex"
        :parent-id="id"
        @uploadedItem="emit('refetchSectionContents')"
      />
      <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Medieninhalt</label>
    </div>

    <div v-show="footerExpanded || footerInView" class="flex-col flex">
      <PageCreateVocabListAndTest
        ref="createVocabRef"
        :section-id="sectionId"
        :parent-id="id"
        @createNewPairOfTermsExercise="emit('addPairOfTermsGame')"
        @createEmptyVocabList="emit('addEmptyVocabList')"
        @createAutomatedVocabListLast="emit('addVocabListExtractingFromLastTextItem')"
        @createAutomatedVocabListFull="emit('addVocabListExtractingFromAllTextItems')"
      />
      <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Vokabelliste</label>
    </div>

    <div v-show="footerExpanded || footerInView" class="flex-col flex">
      <PageCreateExercise
        v-if="!!createVocabRef"
        :section-id="sectionId"
        :parent-id="id"
        :outer-header-height="outerHeaderHeight"
        @createNewPairOfTermsExercise="emit('addPairOfTermsGame')"
        @createNewClassificationExercise="emit('addClassificationExercise')"
        @createNewCloze="emit('addCloze')"
        @createNewMcQuestion="emit('addMcQuestion')"
        @createNewOpenQuestion="emit('addOpenQuestion')"
        @createContentWithQuestions="emit('addContentWithQuestions')"
        :createVocabRef="createVocabRef"
      />
      <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Übung</label>
    </div>

    <div v-show="footerExpanded || footerInView" class="flex-col flex">
      <PageCreateCaseTask
        :section-id="sectionId"
        :parent-id="id"
        @createNewFreeCase="emit('onAddCase')"
        @createNewFormStructuredExercise="emit('addFormStructuredExercise')"
        @createNewFormCaseTask="emit('addFormCaseExercise')"
      />
      <label class="w-full text-center text-sm text-gray-500 dark:text-gray-400">Fall-Simulation</label>
    </div>
  </div>
</template>
