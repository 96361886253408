<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, watch } from 'vue';
import { PairOfTermsItemType } from '@/helper/typing';
import { updateMinTextareaHeight } from '@/helper';
import { is } from '@vee-validate/rules';
import { debounce } from 'lodash';
import MediaHighlightCard from '@/views/courses/MediaHighlightCard.vue';
import ExerciseMediaUploadCard from '@/components/didactics/pair_of_terms/ExerciseMediaUploadCard.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  allowDrag: {
    type: Boolean,
    default: true,
  },
  isCorrect: {
    type: Boolean,
    default: false,
  },
  showResults: {
    type: Boolean,
    default: false,
  },
  draggedOver: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  isItem1: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
    default: 0,
  },
  contentItemId: {
    type: String,
    required: true,
  },
});

const localItem = ref(props.item);

watch(
  () => props.item,
  () => {
    // if editing, we want to retain the local media file, so we can view it even if game not saved
    // if not editing, we need to update this by the external prop to handle drag/drop swaps of cards
    if (props.isEditing) return;
    localItem.value = props.item;
  },
  { immediate: true },
);

const emit = defineEmits(['isDragging', 'inputItem', 'currentDraggingPosition', 'droppedAtPosition', 'scrollBy']);
const isDragging = ref(false);
const textareaRef = ref(null);
const cardRef = ref(null);
const initialOffset = ref({ x: 0, y: 0 });
const touchOrCursorX = ref(undefined);
const touchOrCursorY = ref(undefined);
const scrollInterval = ref<ReturnType<typeof setInterval> | null>(null);

const signalCurrentPosition = () => {
  emit('currentDraggingPosition', { x: touchOrCursorX.value, y: touchOrCursorY.value });
};

const debounceSignalCurrentPosition = debounce(signalCurrentPosition, 50);

const signalStartDragging = () => {
  cardRef.value.style.backdropBrightness = '0.5';
  if (isDragging.value) {
    return;
  }
  isDragging.value = true;
  emit('isDragging', isDragging.value, props.item.id);
};

const signalEndDragging = () => {
  touchOrCursorX.value = undefined;
  touchOrCursorY.value = undefined;
  debounceAutoScroll();
  isDragging.value = false;
  emit('isDragging', isDragging.value, null);
};

const handleDragging = (event: Event) => {
  signalStartDragging();
  if (event.clientX !== 0) touchOrCursorX.value = event.clientX;
  if (event.clientY !== 0) touchOrCursorY.value = event.clientY;
  // note: at the end of drag, 0/0 is sent for a short time. We do not want this, as this leads to short up scroll while drag is resetting.
  debounceAutoScroll(10);
};

const handleTouchStart = (event: Event) => {
  let touch;
  try {
    touch = event.touches[0];
  } catch (e) {
    // event sometimes misses this. ignore for now. TODO: find out why
    return;
  }
  const rect = cardRef.value.getBoundingClientRect();
  initialOffset.value.x = touch.clientX - rect.left;
  initialOffset.value.y = touch.clientY - rect.top;

  signalStartDragging();
};

const handleTouchEnd = (event: Event) => {
  isDragging.value = false;
  stopAutoScroll();
  // this also signal end of dropping. Do not send signal drop end before sending drop position as this would reset ID
  // of dragged item in parent (not necessary at all, but in any case send position first)
  emit('droppedAtPosition', { x: touchOrCursorX.value, y: touchOrCursorY.value });
  cardRef.value.style.position = 'static';
};

const handleTouchMove = (event: Event) => {
  // Prevent the default scroll behavior; needs @touchmove instead of v-touch:drag for access to event
  event.preventDefault();

  // Get the current touch position
  const touch = event.touches[0];
  touchOrCursorX.value = touch.clientX;
  touchOrCursorY.value = touch.clientY;

  // Trigger auto-scroll if near the top or bottom of the viewport
  autoScroll();

  // Set the element's position to follow the touch point directly
  cardRef.value.style.position = 'fixed';
  cardRef.value.style.left = `${touchOrCursorX.value - initialOffset.value.x}px`;
  cardRef.value.style.top = `${touchOrCursorY.value - initialOffset.value.y}px`;

  debounceSignalCurrentPosition();
};

// Auto-scrolling when the drags near screen top/bottom
const autoScroll = (speed: number = 5) => {
  // If close to the top, scroll up
  if (touchOrCursorY.value < 170) {
    startAutoScroll(-speed); // Scroll up by 5px
  }
  // If close to the bottom, scroll down
  else if (touchOrCursorY.value > window.innerHeight - 70) {
    startAutoScroll(speed); // Scroll down by 5px
  } else {
    stopAutoScroll(); // Stop scrolling if in the middle area
  }
};

const debounceAutoScroll = debounce(autoScroll, 2);

const startAutoScroll = (distance: number) => {
  if (scrollInterval.value) return; // Prevent multiple intervals
  scrollInterval.value = setInterval(() => {
    emit('scrollBy', distance);
  }, 20);
};

const stopAutoScroll = () => {
  if (scrollInterval.value) {
    clearInterval(scrollInterval.value);
    scrollInterval.value = null;
  }
};

watch(
  () => props.isEditing,
  () => {
    if (props.isEditing) {
      updateMinTextareaHeight(textareaRef.value);
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (props.isEditing) {
    updateMinTextareaHeight(textareaRef.value);
  }
});
</script>

<template>
  <!-- Card -->
  <a
    ref="cardRef"
    :key="props.item.id"
    class="group z-10 w-full flex flex-col border shadow-sm rounded-xl hover:shadow-md focus:outline-none focus:shadow-md transition dark:bg-neutral-900 dark:border-neutral-800"
    :class="{
      'bg-teal-50 dark:bg-teal-900 border-teal-500 text-teal-500 dark:border-teal-500':
        props.isCorrect && props.showResults,
      'bg-red-50 dark:bg-red-900 border-red-500 text-red-500 dark:border-red-500':
        !props.isCorrect && props.showResults,
      'text-gray-800 hover:text-gray-500': !props.showResults,
      'cursor-move': !isEditing || (props.allowDrag && !props.showResults) || (props.showResults && !props.isCorrect),
      'bg-white ': isEditing || (!isDragging && !props.draggedOver && !props.showResults),
      'bg-gray-200 dark:bg-neutral-800': isDragging || props.draggedOver,
    }"
    :style="{
      width: props.width + 'px',
      opacity: isDragging ? 0.4 : 1,
    }"
    :draggable="(props.allowDrag && !props.showResults) || (props.showResults && !props.isCorrect)"
    @drag.prevent="handleDragging"
    @dragend.prevent="signalEndDragging"
    v-touch:press="handleTouchStart"
    v-touch:release="handleTouchEnd"
    @touchmove="handleTouchMove"
  >
    <div v-if="!!props.item" class="py-1 md:py-2 px-1 md:px-2 flex h-fit" :style="{ width: props.width + 'px' }">
      <div class="flex min-w-full gap-x-5">
        <span
          v-show="!isEditing && !localItem.media_item_id"
          translate="no"
          class="material-symbols-outlined notranslate md:pl-2 select-none text-lg md:text-xl -mr-4 md:mr-0"
          :class="{
            'text-gray-400 dark:text-gray-500': !props.showResults,
            'text-teal-500 dark:text-teal-500': props.isCorrect && props.showResults,
            'text-red-500 dark:text-red-500': !props.isCorrect && props.showResults,
          }"
        >
          {{
            props.showResults && props.isCorrect
              ? 'task_alt'
              : props.showResults && !props.isCorrect
              ? 'dangerous'
              : 'drag_indicator'
          }}
        </span>

        <!-- TODO images and audio -->
        <div class="flex-col flex gap-y-1 w-full">
          <span
            v-show="!isEditing"
            class="select-none flex items-center gap-x-5 text-center text-xs md:text-sm fallback-break"
            :style="{ whiteSpace: 'normal' }"
          >
            <span
              v-show="localItem.media_item_id"
              translate="no"
              class="material-symbols-outlined notranslate md:pl-2 select-none text-lg md:text-xl -mr-4 md:mr-0"
              :class="{
                'text-gray-400 dark:text-gray-500': !props.showResults,
                'text-teal-500 dark:text-teal-500': props.isCorrect && props.showResults,
                'text-red-500 dark:text-red-500': !props.isCorrect && props.showResults,
              }"
            >
              {{
                props.showResults && props.isCorrect
                  ? 'task_alt'
                  : props.showResults && !props.isCorrect
                  ? 'dangerous'
                  : 'drag_indicator'
              }}
            </span>
            {{ props.item.content }}</span
          >
          <textarea
            ref="textareaRef"
            v-show="isEditing"
            type="text"
            class="resize-none text-xs md:text-sm p-2 border border-gray-200 rounded-md dark:bg-neutral-800 dark:border-neutral-700"
            :value="props.item.content"
            :placeholder="props.isItem1 ? 'z.B. ein Begriff' : 'z.B. seine Erklärung'"
            :style="{ width: props.width - 16 + 'px' }"
            @input="
              async (event) => {
                await updateMinTextareaHeight(event.target);
                $emit('inputItem', { ...props.item, content: event.target.value });
              }
            "
            :rows="1"
          />
          <ExerciseMediaUploadCard
            :contentItemId="props.contentItemId"
            v-if="props.isEditing"
            v-show="!localItem.media_item_id"
            @uploaded-item="
              async (mediaItemId, mediaItemType, mediaItemUrl) => {
                $emit('inputItem', { ...props.item, media_item_id: mediaItemId });
                localItem = { ...props.item, media_item_id: mediaItemId };
                localItem.media_item = {
                  media_url: mediaItemUrl,
                  media_item_type: mediaItemType,
                };
              }
            "
          />
          <div class="w-full relative" v-if="!!localItem.media_item_id" :key="props.item.id">
            <MediaHighlightCard
              :content-item-id="null"
              :url="localItem.media_item.media_url"
              :type="localItem.media_item.media_item_type"
              :allow-edit="props.isEditing"
              :max-on-click="false"
              :is-maximized="true"
              :show-description="false"
              :normalize-height="false"
              @removeMediaItem="
                async () => {
                  $emit('inputItem', { ...props.item, media_item_id: null });
                  localItem = { ...props.item, media_item_id: null };
                  localItem.media_item = {
                    media_url: '',
                    media_item_type: '',
                  };
                }
              "
            />
            <div
              v-if="localItem.media_item.media_item_type === 'IMAGE' && !props.isEditing"
              class="absolute w-full h-full top-0 start-0 z-10"
            />
            <!-- this functions as a drag handle over IMAGE -->
          </div>
        </div>
      </div>
    </div>
  </a>
  <!-- End Card -->
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
